export const spacingNumberMap = {
  xs: 0.25, // 4px
  sm: 0.5,
  md: 1,
  lg: 2,
  xl: 4,
}

export const spacing = {
  xs: `${spacingNumberMap.xs}rem`,
  sm: `${spacingNumberMap.sm}rem`,
  md: `${spacingNumberMap.md}rem`,
  lg: `${spacingNumberMap.lg}rem`,
  xl: `${spacingNumberMap.xl}rem`,
}

export const navBarHeightSmall = '4rem'
export const navBarHeightLarge = '5.5rem'

export const navBarHeightSmallToPixels = 4 * 16
export const navBarHeightLargeToPixels = 5.5 * 16

export const colors = {
  joda: '#33707E',
  jodaLight: '#679ead',
  jodaDark: '#004552',
  safe: '#6a1b9a',
  safeLight: '#9c4dcc',
  safeDark: '#38006b',
  warmthLight: '#ff8076',
  warmth: '#ffcdd2',
  warmthDark: '#c50021',
  close: '#ad1457',
  closeLight: '#e35183',
  closeDark: '#78002e',
  trust: '#ffcdd2',
  trustLight: '#ffebee',
  trustDark: '#cb9ca1',
  hope: '#e1f5fe',
  hopeLight: '#f4fcff',
  hopeDark: '#afc2cb',
  black: '#000000',
  black90: '#191919',
  black80: '#333333',
  black70: '#4c4c4c',
  black60: '#666666',
  black50: '#7f7f7f',
  black40: '#999999',
  black30: '#b2b2b2',
  black20: '#cccccc',
  black10: '#e5e5e5',
  black5: '#ececec',
  white: '#ffffff',
  error: '#ff4a4a',
}

export const borderRadius = `${spacingNumberMap.xs}rem`

export const breakpointsNumberMap = {
  XSMALL: 320,
  SMALL: 600,
  MEDIUM: 768,
  LARGE: 992,
  XLARGE: 1200,
}

export const breakpoints = {
  XSMALL: `${breakpointsNumberMap.XSMALL / 16}rem`,
  SMALL: `${breakpointsNumberMap.SMALL / 16}rem`,
  MEDIUM: `${breakpointsNumberMap.MEDIUM / 16}rem`,
  LARGE: `${breakpointsNumberMap.LARGE / 16}rem`,
  XLARGE: `${breakpointsNumberMap.XLARGE / 16}rem`,
}

export const isSmallScreen = () =>
  window.innerWidth <= breakpointsNumberMap.MEDIUM
