export const authenticatedFetch = (
  url: string,
  options?: RequestInit,
  data?: any
): Promise<any> => {
  const token = localStorage.getItem('jodabook-token')

  return fetch(url, {
    ...options,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  }).then(async (response) => {
    if (!response.ok) {
      if (response.status === 401) {
        localStorage.setItem('jodabook-token', '')
        window.location.assign('/login')
        return
      }

      const res = await response.json()
      throw new Error(res)
    }

    return response.json()
  })
}
