import React, { FunctionComponent, useCallback, useEffect } from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  // background: rgba(0, 0, 0, 0.7);
  z-index: 4;
`

type Props = {
  onClose: () => void
  forFiltering?: boolean
}

export const Dialog: FunctionComponent<Props> = ({
  children,
  onClose,
  forFiltering,
}) => {
  const [mountNode] = React.useState(document.getElementById('root'))

  const handleEscapeKey = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape' && event.code === 'Escape' && onClose) {
        event.stopPropagation()
        onClose()
      }
    },
    [onClose]
  )

  useEffect(() => {
    window.addEventListener('keydown', handleEscapeKey)

    return () => window.removeEventListener('keydown', handleEscapeKey)
  })

  if (!mountNode) {
    return null
  }

  return ReactDOM.createPortal(
    <Overlay
      style={{
        background: forFiltering
          ? 'rgba(255, 255, 255, 0.95)'
          : 'rgba(0, 0, 0, 0.7)',
      }}
    >
      {children}
    </Overlay>,
    mountNode
  )
}
