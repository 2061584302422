import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import {
  borderRadius,
  breakpoints,
  colors,
  spacing,
} from '../../../styles/styles'
import { environment } from '../../../environments'
import { authenticatedFetch } from '../../../lib/authenticatedFetch'
import { format } from 'date-fns'
import { nb } from 'date-fns/locale'
import { AnimateHeight } from '../../shared/animations/AnimateHeight'
import { PageContainer } from '../../styled/Layout'
import { LargeAlbumView } from '../../shared/LargeAlbumView'
import { TimelineMedia } from './TimeLineMedia'
import spinner from '../../../lottie/spinner.json'
import { Player } from '@lottiefiles/react-lottie-player'
import { useNavBar } from '../../../navigation/NavBarProvider'

const TimelineWrapper = styled(PageContainer)`
  margin: 0 auto;
  max-width: ${breakpoints.XSMALL};
  height: 100%;
  overflow-y: scroll;
`

const Season = styled.div`
  position: relative;
  padding: ${spacing.md};
`

const SeasonTile = styled.div`
  width: 100%;
  font-family: 'PT Serif', Times New Roman, serif;
  font-weight: bold;
  font-size: inherit;
  border: none;
  display: inline-block;
  padding: ${spacing.sm};
  margin-bottom: ${spacing.md};
  border-radius: ${borderRadius};
  background: ${colors.white};
  transition: all 0.25s ease-out;
  text-align: center;

  &:hover {
    cursor: pointer;
  }

  h4 {
    margin: 0 0 ${spacing.sm};
  }
`

const SeasonTileButton = styled.button`
  width: 100%;
  height: 48px;
  font-size: 1.2rem;
  font-family: inherit;
  border: none;
  background: none;
`

const VerticalLine = styled.div`
  position: fixed;
  width: 0.3rem;
  height: 100vh;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: ${colors.hopeDark};
  z-index: 0;
`

export const TimelinePage = () => {
  const { setTitle } = useNavBar()
  const [status, setStatus] = useState<'idle' | 'pending' | 'failure'>(
    'pending'
  )
  const [periods, setPeriods] = useState<any[]>([])
  const [mediaBySeason, setMediaBySeason] = useState<any>({})
  const [selectedPeriod, setSelectedPeriod] = useState<{
    year: string
    month: string
  }>()
  const [imageIndex, setImageIndex] = useState<number>(0)
  const [images, setImages] = useState<any[] | null>(null)

  useEffect(() => {
    setTitle('Tidslinje')
  }, [setTitle])

  useEffect(() => {
    authenticatedFetch(`${environment.API_URL}/timeline/overview`)
      .then((response) => {
        setStatus('idle')
        setPeriods(response)
      })
      .catch(() => {
        setStatus('failure')
      })
  }, [])

  useEffect(() => {
    if (!selectedPeriod) {
      return
    }

    const { year, month } = selectedPeriod

    authenticatedFetch(
      `${environment.API_URL}/timeline?year=${year}&month=${month}`
    )
      .then(({ results }) => {
        setMediaBySeason((mediaBySeason: any) => ({
          ...mediaBySeason,
          [`${year}_${month}`]: results,
        }))
      })
      .catch(() => {
        setStatus('failure')
      })
  }, [selectedPeriod])

  useEffect(() => {
    const el: any = document.getElementById(
      `${selectedPeriod?.year}-${selectedPeriod?.month}`
    )

    if (el) {
      el.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      })
    }
  }, [selectedPeriod])

  return (
    <TimelineWrapper>
      <VerticalLine />

      {status === 'pending' && (
        <Player
          autoplay
          loop
          src={spinner}
          style={{
            height: '5rem',
            width: '5rem',
            margin: `${spacing.md} auto`,
          }}
        />
      )}

      {periods?.map(({ year, month, media: m }) => {
        if (month < 10) {
          month = `0${month}`
        }

        const showMedia =
          selectedPeriod?.year === year && selectedPeriod?.month === month
        const media = mediaBySeason[`${year}_${month}`] ?? []

        return (
          <React.Fragment>
            <Season
              id={`${year}-${month}`}
              key={`${year}-${month}`}
              style={{ position: 'relative' }}
            >
              <AnimateHeight animateOn={true}>
                <SeasonTile id={month}>
                  <SeasonTileButton
                    onClick={() => {
                      setSelectedPeriod(showMedia ? undefined : { year, month })
                    }}
                  >
                    {format(new Date(`${year}-${month}-01`), 'MMMM yyyy', {
                      locale: nb,
                    })}
                  </SeasonTileButton>

                  {showMedia ? (
                    media.map((m: any, i: number) => (
                      <TimelineMedia
                        key={`m-${m.id}`}
                        media={m}
                        onClick={() => {
                          setImageIndex(i)
                          setImages(media)
                        }}
                      />
                    ))
                  ) : (
                    <TimelineMedia
                      key={`m-${m.id}`}
                      media={m}
                      onClick={() => {
                        setSelectedPeriod(
                          showMedia ? undefined : { year, month }
                        )
                      }}
                    />
                  )}
                </SeasonTile>
              </AnimateHeight>
            </Season>
          </React.Fragment>
        )
      })}

      <Season>
        <SeasonTile style={{ backgroundColor: colors.jodaLight }}>
          Startet med Jodabook
        </SeasonTile>
      </Season>

      {images && (
        <LargeAlbumView
          initialIndex={imageIndex}
          images={images}
          onClose={() => {
            setImageIndex(0)
            setImages(null)
          }}
        />
      )}
    </TimelineWrapper>
  )
}
