import styled from 'styled-components'
import { colors, spacing } from '../../styles/styles'

export const Message = styled.div`
  margin-bottom: ${spacing.lg};
  text-align: center;
  font-weight: bold;
`

export const ErrorMessage = styled(Message)`
  color: ${colors.error};
`
