import React, { FunctionComponent } from 'react'
import { Card } from './post/PostCard'
import ContentLoader from 'react-content-loader'

export const ImageLoader = () => {
  return (
    <Card>
      <ContentLoader
        speed={2}
        width={290}
        height={80}
        viewBox="0 0 290 80"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
      >
        <rect x="0" y="0" rx="4" ry="4" width="90" height="100" />
        <rect x="100" y="0" rx="4" ry="4" width="90" height="100" />
        <rect x="200" y="0" rx="4" ry="4" width="90" height="100" />
      </ContentLoader>
    </Card>
  )
}

type Props = {
  width?: number | string
  height?: number | string
}

export const SingleImageLoader: FunctionComponent<Props> = ({
  width,
  height,
}) => {
  return (
    <Card>
      <ContentLoader
        speed={2}
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
      >
        <rect x="0" y="0" rx="4" ry="4" width={width} height={height} />
      </ContentLoader>
    </Card>
  )
}
