import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { FunctionComponent, ReactNode, useEffect, useState } from 'react'
import styled from 'styled-components'
import {
  borderRadius,
  breakpoints,
  colors,
  navBarHeightLarge,
  spacing,
} from '../../../styles/styles'
import {
  DangerOutlinedButton,
  PrimaryButton,
  SecondaryButton,
} from '../../shared/Buttons'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { Loading } from '../../shared/post/CardImage'
import { Player } from '@lottiefiles/react-lottie-player'
import spinner from '../../../lottie/spinner.json'
import { Link } from 'react-router-dom'
import { GalleryImage } from './GalleryImage'

type Props = {
  status: 'idle' | 'pending' | 'failure'
  images: any[]
  isNative: boolean
  album: { id: string; name: string }
  addImages: () => void
  removeImage: (imageId: string) => void
  deleteAlbum: () => void
  showLargeImage: (imageIndex: number) => void
  addImagesComponent: ReactNode
}

const PageContainer = styled.div`
  max-width: ${breakpoints.XLARGE};
  margin: 0 auto;
  padding-top: calc(${navBarHeightLarge} + ${spacing.md});
  height: calc(100vh - ${spacing.md});
  overflow: hidden;
  border-radius: ${borderRadius};
`

const Wrapper = styled.div`
  position: relative;
  display: flex;
  height: 100%;
  background: white;
`

const LargePreview = styled.div`
  position: relative;
  flex: 3;
  width: calc(100% / 3) * 2;
  text-align: center;
  background: ${colors.black80};

  img {
    width: auto;
    max-width: 100%;
    height: 100%;
    object-fit: cover;
    
    &:hover {
      cursor: pointer;
    }
`

const Gallery = styled.div`
  flex: 1;
  width: calc(100% / 3);
  line-height: 0;
  column-gap: 0;
  column-count: 2;
  overflow-x: hidden;
  overflow-y: scroll;
  background: white;

  img {
    width: 100%;
    height: auto;

    &:hover {
      cursor: pointer;
    }
  }
`

const AlbumMenu = styled.div`
  display: flex;
  gap: ${spacing.sm};
  justify-content: flex-end;
`

const Title = styled.h1`
  margin: 0;
  text-align: center;
`

const AlbumHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding: ${spacing.sm};
  background: white;
`

const EmptyStateText = styled.div`
  padding: ${spacing.md};
  text-align: center;
`

const Error = styled.div`
  color: ${colors.error};
  text-align: center;
`

export const AlbumPageLargeScreen: FunctionComponent<Props> = ({
  status,
  images,
  isNative,
  addImages,
  album,
  removeImage,
  deleteAlbum,
  showLargeImage,
  addImagesComponent,
}) => {
  const [selectedIndex, setSelectedIndex] = useState<number>(0)
  const [isEditMode, setIsEditMode] = useState<boolean>(false)
  const [isLoadingLargeImage, setIsLoadingLargeImage] = useState(true)

  useEffect(() => {
    setIsLoadingLargeImage(true)

    return () => setIsLoadingLargeImage(false)
  }, [selectedIndex])

  if (status === 'pending') {
    return (
      <PageContainer>
        <Loading>
          <Player
            autoplay
            loop
            src={spinner}
            style={{ height: '9.375rem', width: '9.375rem' }}
          />
        </Loading>
      </PageContainer>
    )
  }

  return (
    <PageContainer>
      {status === 'failure' ? (
        <Error>Det oppstod dessverre en feil</Error>
      ) : (
        <React.Fragment>
          <AlbumHeader>
            <Link className="link" to="/album">
              <FontAwesomeIcon
                style={{ marginRight: spacing.sm }}
                icon={faArrowLeft}
              />
              Tilbake til album
            </Link>

            <Title>{album?.name}</Title>

            <AlbumMenu>
              {isNative && (
                <PrimaryButton
                  label="Legg til bilder"
                  onClick={addImages}
                  isSmall={true}
                />
              )}

              {addImagesComponent}

              <SecondaryButton onClick={() => setIsEditMode(!isEditMode)}>
                {isEditMode ? 'Avbryt' : 'Endre'}
              </SecondaryButton>

              <DangerOutlinedButton
                aria-label={`Slett albumet ${album.name}`}
                onClick={deleteAlbum}
              >
                Slett
              </DangerOutlinedButton>
            </AlbumMenu>
          </AlbumHeader>

          {images.length > 0 ? (
            <Wrapper>
              <LargePreview>
                {isLoadingLargeImage && (
                  <Loading>
                    <Player
                      autoplay
                      loop
                      src={spinner}
                      style={{ height: '9.375rem', width: '9.375rem' }}
                    />
                  </Loading>
                )}
                <img
                  onClick={() => showLargeImage(selectedIndex)}
                  alt="Bilde"
                  style={isLoadingLargeImage ? { opacity: 0 } : { opacity: 1 }}
                  onLoad={() => setIsLoadingLargeImage(false)}
                  src={images[selectedIndex].medium}
                />
              </LargePreview>
              <Gallery>
                {images.map((image, i) => (
                  <GalleryImage
                    key={`gallery-img-${i}`}
                    selected={i === selectedIndex}
                    isEditMode={isEditMode}
                    onSelect={() => setSelectedIndex(i)}
                    image={image}
                    onRemoveImage={() => removeImage(image)}
                  />
                ))}
              </Gallery>
            </Wrapper>
          ) : (
            <EmptyStateText>
              Det er ikke lagt til noen bilder i dette albumet ennå
            </EmptyStateText>
          )}
        </React.Fragment>
      )}
    </PageContainer>
  )
}
