import React, { useEffect, useState } from 'react'
import { authenticatedFetch } from '../../../lib/authenticatedFetch'
import { PageContainer } from '../../styled/Layout'
import { environment } from '../../../environments'
import { PostLoader } from '../../shared/post/PostLoader'
import { useParams } from 'react-router-dom'
import { Post } from '../../shared/post/Post'
import { FeedHeader } from './FeedHeader'
import { ConfirmDialog } from '../../shared/modal/ConfirmDialog'

export const PostDetailPage = () => {
  const { postId } = useParams<{ postId: string }>()
  const [status, setStatus] = useState<'idle' | 'pending' | 'failure'>('idle')
  const [post, setPost] = useState<any | null>(null)
  const [deletingPostId, setDeletingPostId] = useState<string>('')
  const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false)

  useEffect(() => {
    setStatus('pending')
    authenticatedFetch(`${environment.API_URL}/posts/${postId}`)
      .then((result) => {
        setStatus('idle')
        setPost(result)
      })
      .catch(() => {
        setStatus('failure')
      })
  }, [postId])

  const deletePost = () => {
    setShowConfirmDelete(false)

    authenticatedFetch(`${environment.API_URL}/posts/${deletingPostId}`, {
      method: 'DELETE',
    })
      .then(() => {
        setPost(null)
        setDeletingPostId('')
        setStatus('failure')
      })
      .catch(() => {})
  }
  return (
    <PageContainer>
      {status === 'pending' && !post ? (
        <PostLoader />
      ) : status === 'failure' ? (
        <div>Det oppstod dessverre en feil</div>
      ) : post ? (
        <React.Fragment>
          <Post
            key={`p-1`}
            post={post}
            onDelete={() => {
              setShowConfirmDelete(true)
              setDeletingPostId(post.id)
            }}
            onVideoFetchingCompleted={(result: any) =>
              setPost(result.find((x: any) => x.id === post.id) ?? post)
            }
          />
          <ConfirmDialog
            show={showConfirmDelete}
            title="Slette innlegg?"
            onClose={() => {
              setShowConfirmDelete(false)
              setDeletingPostId('')
            }}
            onConfirm={deletePost}
            onCancel={() => {
              setShowConfirmDelete(false)
              setDeletingPostId('')
            }}
          />
        </React.Fragment>
      ) : (
        <FeedHeader />
      )}
    </PageContainer>
  )
}
