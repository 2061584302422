import React from 'react'
import ContentLoader from 'react-content-loader'
import { PostCard } from './Post'

export const PostLoader = () => {
  const innerWidth = window.innerWidth
  const defaultWidth = 560
  const width = innerWidth <= defaultWidth ? innerWidth : defaultWidth

  return (
    <PostCard>
      <ContentLoader
        speed={2}
        width={width}
        height={410}
        viewBox={`0 0 ${width} 410`}
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
      >
        <rect x="16" y="18" rx="2" ry="2" width="140" height="10" />
        <rect x="16" y="34" rx="2" ry="2" width="110" height="10" />
        <rect x="16" y="60" rx="2" ry="2" width={width - 32} height="340" />
      </ContentLoader>
    </PostCard>
  )
}
