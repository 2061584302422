import React, { FunctionComponent, useEffect, useState } from 'react'
import { Player } from '@lottiefiles/react-lottie-player'
import spinner from '../../lottie/spinner.json'
import { Dialog } from './modal/Dialog'
import styled from 'styled-components'
import {
  borderRadius,
  breakpoints,
  colors,
  isSmallScreen,
  spacing,
} from '../../styles/styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { CardVideo } from './post/CardVideo'

const LargeImg = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
  background: ${colors.black90};

  @media only screen and (min-width: ${breakpoints.LARGE}) {
    margin-left: auto;
    margin-right: auto;
    top: 5%;
    width: 90%;
    height: 90%;
    text-align: center;
    padding: ${spacing.lg};
    background: white;
    border-radius: ${borderRadius};
  }

  img {
    width: 100%;
    height: auto;
    max-height: 100%;
    opacity: 0;
    object-fit: contain;
    transition: opacity 0.25s ease-out;
  }

  .imageLoaded {
    opacity: 1;
  }
`

const Loading = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const CloseButton = styled.button`
  position: absolute;
  top: 1rem;
  right: 1rem;
  padding: ${spacing.sm} ${spacing.md};
  border: 2px solid ${colors.black90};
  border-radius: ${borderRadius};
  font-weight: bold;
  background: white;
`

const ImageNavButton = styled.button`
  position: absolute;
  padding: ${spacing.md};
  border: none;
  background: white;
  transition: all 0.25s ease-out;
  z-index: 2;

  &:active:not(:disabled) {
    color: white;
    background: ${colors.safeDark};
  }

  svg {
    font-size: 2rem;
  }
`

const PrevButton = styled(ImageNavButton)`
  left: 0;
  border-top-right-radius: ${borderRadius};
  border-bottom-right-radius: ${borderRadius};
`

const NextButton = styled(ImageNavButton)`
  right: 0;
  border-top-left-radius: ${borderRadius};
  border-bottom-left-radius: ${borderRadius};
`

type Props = {
  images: any[]
  initialIndex: number
  onClose: () => void
}

export const LargeAlbumView: FunctionComponent<Props> = ({
  images,
  initialIndex,
  onClose,
}) => {
  const [imageIndex, setImageIndex] = useState<number>(initialIndex ?? 0)
  const isVideo = images[imageIndex].mp4_urls
  const [imageLoading, setImageLoading] = useState<boolean>(!isVideo)

  const canGoBack = imageIndex !== 0
  const canGoNext = imageIndex + 1 !== images.length

  const goToPrevImage = () => setImageIndex((index) => index - 1)
  const goToNextImage = () => setImageIndex((index) => index + 1)

  useEffect(() => setImageLoading(!isVideo), [imageIndex, isVideo])

  return (
    <Dialog onClose={onClose}>
      <LargeImg>
        <CloseButton onClick={onClose}>Lukk</CloseButton>

        {imageLoading && (
          <Loading>
            <Player
              autoplay
              loop
              src={spinner}
              style={{ height: '9.375rem', width: '9.375rem' }}
            />
          </Loading>
        )}

        <PrevButton onClick={goToPrevImage} disabled={!canGoBack}>
          <FontAwesomeIcon icon={faArrowLeft} />
        </PrevButton>

        {isVideo ? (
          <CardVideo
            video={images[imageIndex]}
            onVideoFetchingCompleted={() => {}}
          />
        ) : (
          <img
            className={imageLoading ? '' : 'imageLoaded'}
            onLoad={() => setImageLoading(false)}
            onError={() => setImageLoading(false)}
            alt="Bilde fra galleri"
            src={
              isSmallScreen()
                ? images[imageIndex].small
                : images[imageIndex].large
            }
          />
        )}

        <NextButton onClick={goToNextImage} disabled={!canGoNext}>
          <FontAwesomeIcon icon={faArrowRight} />
        </NextButton>
      </LargeImg>
    </Dialog>
  )
}
