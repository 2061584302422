export const devApiUrl = 'https://jodacare-backend-staging.herokuapp.com/api/v3'

const env = process.env.REACT_APP_ENV

export const environment = {
  API_URL:
    env === 'production'
      ? 'https://api.jodacare.com/api/v3'
      : env === 'local'
      ? 'http://localhost:5000/api/v3'
      : devApiUrl,
}
