import {
  faGrinHearts,
  faAngry,
  faSurprise,
  faLaughBeam,
  faSadTear,
  faSmileBeam,
} from '@fortawesome/free-solid-svg-icons'

export const reactionsConst = [
  { icon: faGrinHearts, name: 'Liker veldig', emotion: 'LIKE_A_LOT' },
  { icon: faLaughBeam, name: 'Gøy', emotion: 'FUN' },
  { icon: faSurprise, name: 'Spennende', emotion: 'EXITING' },
  { icon: faSadTear, name: 'Trist', emotion: 'SAD' },
  { icon: faAngry, name: 'Sint', emotion: 'ANGRY' },
  { icon: faSmileBeam, name: 'Glad', emotion: 'HAPPY' },
]
