import React, { FunctionComponent, useEffect } from 'react'
import { environment } from '../../../environments'
import { List } from '../../shared/list/List'
import { VideoContactListItem } from '../../shared/list/VideoContactListItem'
import styled from 'styled-components'
import { useNavBar } from '../../../navigation/NavBarProvider'
import { breakpoints } from '../../../styles/styles'

const ListContainer = styled.div`
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  column-gap: 1rem;

  @media only screen and (min-width: ${breakpoints.MEDIUM}) {
    grid-template-columns: repeat(12, 1fr);
  }
`

export const ContactPage: FunctionComponent = () => {
  const { setTitle } = useNavBar()

  useEffect(() => setTitle('Kontakter'), [setTitle])

  return (
    <ListContainer>
      <List
        ListItem={VideoContactListItem}
        url={`${environment.API_URL}/video-calls/contacts`}
        emptyState="Ingen kontakter"
      />
    </ListContainer>
  )
}
