import styled from 'styled-components'
import { colors } from '../../styles/styles'
import React, { CSSProperties, FunctionComponent } from 'react'
import fallback from '../../images/no-profile-img.png'

type AvatarProps = {
  size: 'small' | 'medium'
}

const AvatarStyled = styled.img`
  width: ${({ size }: AvatarProps) => (size === 'small' ? '60px' : '250px')};
  height: ${({ size }: AvatarProps) => (size === 'small' ? '60px' : '250px')};
  object-fit: cover;
  border-radius: 50%;
  border: 1px solid ${colors.black5};
  background: ${colors.black5};
`

type Props = {
  image: any
  size: 'small' | 'medium'
  alt: string
  style?: Partial<CSSProperties>
  onClick?: () => void
}

export const Avatar: FunctionComponent<Props> = ({
  image,
  size,
  alt,
  style,
  onClick,
}) => {
  const src = image ? image[size] : fallback
  return (
    <AvatarStyled
      onClick={onClick}
      style={style}
      src={src}
      alt={alt}
      size={size}
    />
  )
}
