import React, { FunctionComponent, useEffect, useState } from 'react'
import styled from 'styled-components'
import { authenticatedFetch } from '../../../lib/authenticatedFetch'
import { ListItemLoader } from '../ListItemLoader'
import { breakpoints } from '../../../styles/styles'

const ListStyled = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  grid-column: span 6 / span 6;
  @media only screen and (min-width: ${breakpoints.MEDIUM}) {
    grid-column: span 6 / span 6;
    grid-column-start: 4;
    margin-top: 100px;
  }
`

type Props = {
  ListItem: FunctionComponent<{ data: any }>
  url: string
  emptyState: string
}

export const List: FunctionComponent<Props> = ({
  emptyState,
  url,
  ListItem,
}) => {
  const [status, setStatus] = useState<'idle' | 'pending' | 'failure'>(
    'pending'
  )
  const [items, setItems] = useState<any[]>()

  useEffect(() => {
    authenticatedFetch(url)
      .then((response) => {
        setStatus('idle')
        setItems(response.results ?? response)
      })
      .catch((error) => {
        setStatus('failure')
      })
  }, [url])

  if (status === 'pending') {
    return <ListItemLoader />
  }

  if (items?.length === 0) {
    return <div>{emptyState}</div>
  }

  return (
    <ListStyled>
      {items?.map((item) => (
        <ListItem key={item.id} data={item} />
      ))}
    </ListStyled>
  )
}
