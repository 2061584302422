import { Card } from '../../shared/post/PostCard'
import {
  breakpointsNumberMap,
  spacing,
  spacingNumberMap,
} from '../../../styles/styles'
import { SingleImageLoader } from '../../shared/ImageLoader'
import React, { FunctionComponent } from 'react'

type Props = {
  width?: number
}

export const AlbumLoader: FunctionComponent<Props> = ({ width }) => {
  const defaultWidth = width
    ? width
    : window.screen.availWidth < breakpointsNumberMap.SMALL
    ? window.screen.availWidth
    : breakpointsNumberMap.SMALL

  return (
    <React.Fragment>
      <Card
        style={{
          margin: '0 auto',
          width,
          padding: `${spacing.sm} ${spacing.sm} 0 ${spacing.sm}`,
          height: '15rem',
        }}
      >
        <SingleImageLoader
          width={defaultWidth - spacingNumberMap.md}
          height={240 - spacingNumberMap.md}
        />
      </Card>
    </React.Fragment>
  )
}
