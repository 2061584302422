import React, { useEffect, useState } from 'react'
import { PageContainer } from '../../styled/Layout'
import { authenticatedFetch } from '../../../lib/authenticatedFetch'
import { environment } from '../../../environments'
import styled from 'styled-components'
import {
  borderRadius,
  breakpoints,
  colors,
  spacing,
} from '../../../styles/styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faImages } from '@fortawesome/free-solid-svg-icons'
import { Link, useHistory } from 'react-router-dom'
import { NewAlbumDialog } from './NewAlbumDialog'
import { TextLoader } from '../../shared/TextLoader'
import { reactionsConst } from '../../../constants'
import { useNavBar } from '../../../navigation/NavBarProvider'
import { AlbumImage } from './AlbumImage'

const NewAlbumButton = styled.button`
  display: none;

  @media only screen and (min-width: ${breakpoints.MEDIUM}) {
    display: inline-block;
    position: inherit;
    top: ${spacing.md};
    right: ${spacing.sm};
    margin-bottom: ${spacing.md};
    padding: ${spacing.sm} ${spacing.md};
    min-height: 3rem;
    color: white;
    background: ${colors.safeDark};
    text-decoration: none;
    border: none;
    font-size: inherit;
    border-radius: ${borderRadius};

    &:active {
      background: ${colors.safeLight};
    }
  }
`

const Albums = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: ${spacing.sm};
  flex-wrap: wrap;
`

const AlbumLink = styled(Link)`
  display: flex;
  padding-bottom: ${spacing.md};
  flex-direction: column;
  color: inherit;
  width: calc(50vw - ${spacing.sm});
  border-radius: ${borderRadius};
  background-color: white;
  justify-content: center;
  align-items: center;
  text-decoration: none;

  @media only screen and (min-width: ${breakpoints.MEDIUM}) {
    width: calc((100% / 3) - ${spacing.sm});
  }
`

const AlbumTitle = styled.div`
  text-overflow: ellipsis;
`

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 10rem;
`

const Icon = styled(FontAwesomeIcon)`
  font-size: 3rem;
`

type Tag = {
  creatorId: string
  id: string
  name: string
  emotion: string | null
  thumbnail: any | null
}

export const AllAlbumsPage = () => {
  const history = useHistory()
  const { setTitle, setRightButtons } = useNavBar()
  const [status, setStatus] = useState<'idle' | 'pending' | 'failure'>(
    'pending'
  )
  const [tags, setTags] = useState<Tag[]>([])
  const [showCreateAlbumDialog, setShowCreateAlbumDialog] = useState<boolean>(
    false
  )

  useEffect(() => setTitle('Album'), [setTitle])
  useEffect(() => {
    setRightButtons([
      { label: 'Nytt album', onClick: () => setShowCreateAlbumDialog(true) },
    ])

    return () => setRightButtons([])
  }, [setRightButtons, setShowCreateAlbumDialog])

  useEffect(() => {
    authenticatedFetch(`${environment.API_URL}/tags`)
      .then((data) => {
        setTags(data)
        setStatus('idle')
      })
      .catch(() => {
        setStatus('failure')
      })
  }, [])

  return (
    <PageContainer>
      {status === 'pending' ? (
        <TextLoader />
      ) : (
        <React.Fragment>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <NewAlbumButton onClick={() => setShowCreateAlbumDialog(true)}>
              Nytt album
            </NewAlbumButton>
          </div>

          <Albums>
            {tags?.map((tag, i) => {
              const icon: any =
                (tag &&
                  tag.emotion &&
                  reactionsConst.find((x: any) => x.emotion === tag.emotion)
                    ?.icon) ??
                null

              return (
                <AlbumLink to={`/album/${tag.id}`} key={`alb-${i}`}>
                  {tag.thumbnail ? (
                    <AlbumImage
                      src={tag.thumbnail.small}
                      alt=""
                      maxHeight={10}
                    />
                  ) : (
                    <IconWrapper>
                      <Icon icon={faImages} />
                    </IconWrapper>
                  )}
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginTop: spacing.md,
                    }}
                  >
                    {icon && (
                      <FontAwesomeIcon
                        style={{
                          marginRight: spacing.sm,
                          fontSize: '1.5rem',
                        }}
                        icon={icon}
                      />
                    )}
                    <AlbumTitle>{tag.name}</AlbumTitle>
                  </div>
                </AlbumLink>
              )
            })}
          </Albums>

          {showCreateAlbumDialog && (
            <NewAlbumDialog
              onAlbumCreated={(tag) => history.push(`/album/${tag.id}`)}
              onCancel={() => setShowCreateAlbumDialog(false)}
            />
          )}
        </React.Fragment>
      )}
    </PageContainer>
  )
}
