import React, { FunctionComponent, ReactNode, useEffect, useState } from 'react'
import styled from 'styled-components'
import { DangerButton, PrimaryButton } from '../../shared/Buttons'
import { spacing } from '../../../styles/styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { useNavBar } from '../../../navigation/NavBarProvider'
import { AlbumLoader } from './AlbumLoader'

const Gallery = styled.div`
  line-height: 0;
  column-gap: 0;
  column-count: 2;

  img {
    width: 100% !important;
    height: auto !important;
  }
`

const ImageTile = styled.div`
  position: relative;
`

type Props = {
  status: 'idle' | 'pending' | 'failure'
  images: any[]
  isNative: boolean
  album: { id: string; name: string }
  addImages: () => void
  removeImage: (imageId: string) => void
  deleteAlbum: () => void
  showLargeImage: (imageIndex: number) => void
  addImagesComponent: ReactNode
}

export const AlbumPageSmallScreen: FunctionComponent<Props> = ({
  status,
  images,
  isNative,
  addImages,
  album,
  removeImage,
  deleteAlbum,
  showLargeImage,
  addImagesComponent,
}) => {
  const { setTitle, setRightButtons } = useNavBar()
  const [isEditMode, setIsEditMode] = useState(false)

  useEffect(() => setTitle(album?.name ?? 'Album'), [setTitle, album])

  useEffect(() => {
    const toggleEditMode = () => setIsEditMode((prevState) => !prevState)

    setRightButtons([
      { label: isEditMode ? 'Avbryt' : 'Endre', onClick: toggleEditMode },
    ])

    return () => setRightButtons([])
  }, [setRightButtons, setIsEditMode, isEditMode])

  return (
    <React.Fragment>
      {status !== 'pending' && images.length === 0 && (
        <div
          style={{
            margin: `${spacing.md} auto`,
            display: 'flex',
            flexDirection: 'column',
            maxWidth: '80%',
            textAlign: 'center',
          }}
        >
          <div>Det er ikke lagt til noen bilder i dette albumet ennå</div>

          {isNative && (
            <PrimaryButton
              label="Legg til bilder"
              onClick={addImages}
              isSmall={true}
            />
          )}
        </div>
      )}

      <Gallery>
        {status === 'pending' && images.length === 0 ? (
          <React.Fragment>
            <AlbumLoader width={window.screen.availWidth / 2} />
            <AlbumLoader width={window.screen.availWidth / 2} />
            <AlbumLoader width={window.screen.availWidth / 2} />
            <AlbumLoader width={window.screen.availWidth / 2} />
          </React.Fragment>
        ) : (
          images.map((image, index) => (
            <ImageTile>
              <img
                onClick={() => showLargeImage(index)}
                loading="lazy"
                alt={`Bilde fra albumet ${album?.name}`}
                src={image.small}
                key={image.id}
              />

              {isEditMode && (
                <DangerButton
                  onClick={() => removeImage(image)}
                  style={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                  }}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </DangerButton>
              )}
            </ImageTile>
          ))
        )}
      </Gallery>

      {album && addImagesComponent}

      {isEditMode && (
        <DangerButton
          style={{
            position: 'fixed',
            width: '100%',
            minHeight: '3rem',
            bottom: 0,
          }}
          onClick={() => {
            deleteAlbum()
            setIsEditMode(false)
          }}
        >
          Slett album
        </DangerButton>
      )}
    </React.Fragment>
  )
}
