import React, { FunctionComponent, SyntheticEvent, useState } from 'react'
import { borderRadius, colors, spacing } from '../../styles/styles'
import { PrimaryButton } from './Buttons'
import {
  addImageToAlbum,
  createImage,
  extractImageDataFromFile,
  getUploadUrlForFile,
  uploadBlob,
} from '../../lib/media.service'
import styled from 'styled-components'
import { Dialog } from './modal/Dialog'
import { DialogCard } from './modal/DialogCard'

const PreviewImages = styled.div`
  display: flex;
  gap: ${spacing.md};
`

const PreviewImageWrapper = styled.div`
  height: 10rem;
  width: calc(100% / 3);
`

const FileUploadButton = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background: ${colors.safeDark};
  padding: 0 ${spacing.lg};
  border-radius: ${borderRadius};
  text-transform: uppercase;
  height: 3rem;

  &:disabled {
    background: ${colors.black20};
  }

  &:active {
    background: ${colors.safeLight};
  }

  input {
    display: none;
  }
`

type Props = {
  albumId: string
  onImagesAdded: () => void
}

export const AddImagesToAlbum: FunctionComponent<Props> = ({
  albumId,
  onImagesAdded,
}) => {
  const [uploadStatus, setUploadStatus] = useState<
    'idle' | 'pending' | 'failure'
  >('idle')

  const [previewImages, setPreviewImages] = useState<
    { file: File; preview: string }[]
  >([])

  const onFileChange = (event: SyntheticEvent) => {
    const target = event.target as HTMLInputElement

    if (target.files && target.files.length > 0) {
      const file = target.files[0]
      setPreviewImages((prev) => [
        ...prev,
        { file, preview: URL.createObjectURL(file) },
      ])
    }
  }

  const uploadImages = async () => {
    try {
      setUploadStatus('pending')

      await Promise.all(
        previewImages.map(async ({ file, preview }) => {
          const extractImageData = await extractImageDataFromFile(file)
          const addFilenameToImageData = (filename: string) =>
            extractImageData(filename)
          const uploadImage = uploadBlob(preview)
          const getImageUploadUrl = () => getUploadUrlForFile(file)
          const addImageToProfileAlbum = addImageToAlbum(albumId)

          return getImageUploadUrl()
            .then(uploadImage)
            .then(addFilenameToImageData)
            .then(createImage)
            .then(addImageToProfileAlbum)
        })
      )

      setUploadStatus('idle')
      setPreviewImages([])

      onImagesAdded()
    } catch (error) {
      setUploadStatus('failure')
    }
  }

  return previewImages.length > 0 ? (
    <Dialog onClose={() => setPreviewImages([])}>
      <DialogCard>
        <h1 style={{ marginTop: 0, textAlign: 'center' }}>
          Legg til bilder i album
        </h1>

        {previewImages.length ? (
          <React.Fragment>
            <PreviewImages>
              {previewImages.map(
                (image: { file: File; preview: string }, i) => (
                  <PreviewImageWrapper key={`preview-${i}`}>
                    <img
                      alt="preview"
                      style={{
                        width: 'auto',
                        height: '100%',
                      }}
                      src={image.preview}
                    />
                  </PreviewImageWrapper>
                )
              )}
            </PreviewImages>

            <div
              style={{
                marginTop: spacing.md,
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <PrimaryButton
                onClick={uploadImages}
                disabled={uploadStatus === 'pending'}
                label={
                  uploadStatus === 'pending'
                    ? 'Laster opp bilder...'
                    : 'Lagre i album'
                }
              />
            </div>
          </React.Fragment>
        ) : null}

        {uploadStatus === 'failure' && (
          <div
            style={{
              marginTop: spacing.md,
              color: colors.error,
              textAlign: 'center',
            }}
          >
            Det oppstod dessverre en feil. Forsøk igjen senere.
          </div>
        )}
      </DialogCard>
    </Dialog>
  ) : (
    <FileUploadButton>
      Legg til bilder
      <input type="file" onChange={onFileChange} accept="image/jpeg,jpg,png" />
    </FileUploadButton>
  )
}
