import React, { useContext, useEffect } from 'react'
import { AuthenticatedApp } from './AuthenticatedApp'
import styled from 'styled-components'
import { colors } from './styles/styles'
import { AuthContext } from './contexts/AuthContext'
import { UnAuthenticatedApp } from './UnAuthenticatedApp'
import { VideoCallProvider } from './contexts/VideoCallsProvider'
import { useClearCache } from 'react-clear-cache'
import { useHistory } from 'react-router-dom'
import { NavBarProvider } from './navigation/NavBarProvider'

type Props = {
  isAuthenticated: boolean
}

const Background = styled.div<Props>`
  min-height: 100%;
  background-color: ${({ isAuthenticated }) =>
    isAuthenticated ? colors.hope : colors.trustLight};
`

function App() {
  const { isLatestVersion, emptyCacheStorage } = useClearCache()
  const { authToken } = useContext(AuthContext)

  const isAuthenticated = Boolean(authToken)
  const history = useHistory()
  useEffect(() => {
    // @ts-ignore
    const isNative = window?.ReactNativeWebView
    if (isNative) {
      // @ts-ignore
      if (window._forNotifications === 'YES' && window._postId) {
        // @ts-ignore
        history.push(`/post/${window._postId}`)
      }
      return
    }
    // @ts-ignore
  }, [history])

  useEffect(() => {
    if (!isLatestVersion) {
      // eslint-disable-next-line no-console
      console.log('Clear cache')

      emptyCacheStorage()

      // @ts-ignore
      if (window?.ReactNativeWebView) {
        // @ts-ignore
        window?.ReactNativeWebView.postMessage(
          JSON.stringify({
            type: 'RELOAD_WEBVIEW',
            payload: {},
          })
        )
      }
    }
  }, [isLatestVersion, emptyCacheStorage])

  return (
    <Background isAuthenticated={isAuthenticated}>
      {isAuthenticated ? (
        <VideoCallProvider>
          <NavBarProvider>
            <AuthenticatedApp />
          </NavBarProvider>
        </VideoCallProvider>
      ) : (
        <UnAuthenticatedApp />
      )}
    </Background>
  )
}

export default App
