import React, { useContext } from 'react'
import { PageContainer } from '../../styled/Layout'
import { AuthContext } from '../../../contexts/AuthContext'
import { Switch } from '../../shared/Switch'
import styled from 'styled-components'
import { borderRadius, breakpoints, spacing } from '../../../styles/styles'
import { DangerOutlinedButton } from '../../shared/Buttons'

const SettingsContainer = styled(PageContainer)`
  max-width: ${breakpoints.SMALL};
`

const SettingsList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`

const SettingsListItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${spacing.md};
  margin-bottom: ${spacing.md};
  border-radius: ${borderRadius};
  background: white;

  h2 {
    margin: 0;
    font-size: 1rem;
  }
`

const jodabookSettingsMap: {
  [key: string]: { label: string; notes?: string }
} = {
  jodabook_text_to_speech: {
    label: 'Tekst til tale',
    notes: 'Kun på mobil',
  },
  // jodabook_book: {label: 'Vis boka'},
  jodabook_publish: {
    label: 'Publisere nye innlegg i boka',
    notes: 'Kun på mobil',
  },
  // jodabook_calendar: {label: 'Vis kalender'},
  jodabook_contacts: { label: 'Vis kontakter' },
}

export const SettingsPage = () => {
  const { settings, updateSettings, logout } = useContext(AuthContext)
  const jodabookSettings = settings
    ? Object.keys(settings).filter((key) => key.includes('jodabook_'))
    : []
  const jodabookNotificationSettings = {
    notify_new_messages: settings.notify_new_messages,
    notify_new_posts: settings.notify_new_posts,
    notify_new_comments: settings.notify_new_comments,
    notify_new_events: settings.notify_new_events,
    notify_cancel_events: settings.notify_cancel_events,
    notify_reactions: settings.notify_reactions,
  }

  function areNotificationsAllowed(obj: any) {
    for (let o in obj) if (!obj[o]) return false
    return true
  }

  return settings ? (
    <SettingsContainer>
      <SettingsList>
        {jodabookSettings.map((key) => {
          const label = jodabookSettingsMap[key]?.label ?? ''
          const notes = jodabookSettingsMap[key]?.notes ?? ''
          const value = settings[key]

          return label ? (
            <SettingsListItem key={key}>
              <div>
                <h2>{label}</h2>
                <small>{notes}</small>
              </div>

              <Switch
                checked={value}
                onChange={(checked) => updateSettings({ [key]: checked })}
              />
            </SettingsListItem>
          ) : null
        })}
        <SettingsListItem>
          <div>
            <h2>Varsler</h2>
            <small>Kun på mobil</small>
          </div>
          <Switch
            checked={areNotificationsAllowed(jodabookNotificationSettings)}
            onChange={(checked) =>
              updateSettings({
                notify_new_messages: checked,
                notify_new_posts: checked,
                notify_new_comments: checked,
                notify_new_events: checked,
                notify_cancel_events: checked,
                notify_reactions: checked,
              })
            }
          />
        </SettingsListItem>
      </SettingsList>

      <div style={{ textAlign: 'center' }}>
        <DangerOutlinedButton onClick={logout}>Logg ut</DangerOutlinedButton>
      </div>
    </SettingsContainer>
  ) : null
}
