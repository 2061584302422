import { Card } from '../../shared/post/PostCard'
import { Dialog } from '../../shared/modal/Dialog'
import React, { FunctionComponent, useState } from 'react'
import styled from 'styled-components'
import { breakpoints, colors, spacing } from '../../../styles/styles'
import { PrimaryButton, SecondaryButton } from '../../shared/Buttons'
import { TextLoader } from '../../shared/TextLoader'
import { authenticatedFetch } from '../../../lib/authenticatedFetch'
import { environment } from '../../../environments'

const DialogCard = styled(Card)`
  position: fixed;
  width: 100vw;
  bottom: 0;
  padding: ${spacing.lg};
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;

  @media only screen and (min-width: ${breakpoints.LARGE}) {
    position: relative;
    top: 10rem;
    left: 50%;
    transform: translateX(-50%);
    max-width: ${breakpoints.SMALL};
  }
`

const DialogTitle = styled.h1`
  margin: 0;

  @media only screen and (max-width: ${breakpoints.MEDIUM}) {
    margin-bottom: ${spacing.md};
    font-size: 1.2rem;
  }
`

const DialogActions = styled.div`
  display: flex;
  flex-direction: column;

  button {
    height: 3rem;

    &:last-of-type {
      margin: ${spacing.md} 0 0 0;
    }
  }

  @media only screen and (min-width: ${breakpoints.LARGE}) {
    margin-top: ${spacing.lg};
    flex-direction: row;
    justify-content: flex-end;

    button {
      height: 2.5rem;

      &:last-of-type {
        margin: 0 0 0 ${spacing.md};
      }
    }
  }
`

const FormField = styled.div`
  display: block;
  width: 100%;
  margin-top: ${spacing.md};
  margin-bottom: ${spacing.lg};
`

const Label = styled.label`
  display: block;
  width: 100%;
  text-transform: uppercase;
`

const TextInput = styled.input`
  display: block;
  width: 100%;
  font-size: inherit;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid black;
  border-radius: 0;
`

const Error = styled.div`
  margin: ${spacing.md} 0;
  color: ${colors.error};
`

const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`

const ListItem = styled.li`
  margin-bottom: ${spacing.sm};

  &:not(:last-child) {
    border-bottom: 1px solid ${colors.black20};
  }
`

const ListItemButton = styled.button`
  width: 100%;
  padding: ${spacing.sm};
  font-size: inherit;
  font-family: inherit;
  border: none;
  text-align: left;
  background: white;

  &:hover {
    cursor: pointer;
  }

  &:active {
    background: ${colors.black10};
  }
`

type Props = {
  onCancel: () => void
  onAlbumCreated: (tag: any) => void
}

const predefinedAlbumTitles = [
  'Meg på tur',
  'Meg på jobb',
  'Meg på skolen',
  'Tull og Tøys',
]

export const NewAlbumDialog: FunctionComponent<Props> = ({
  onAlbumCreated,
  onCancel,
}) => {
  const [status, setStatus] = useState<'idle' | 'pending' | 'failure'>('idle')
  const [name, setName] = useState<string>('')

  const createAlbum = () => {
    setStatus('pending')

    authenticatedFetch(
      `${environment.API_URL}/tags`,
      { method: 'POST' },
      { name }
    )
      .then((data) => {
        onAlbumCreated(data)
        setStatus('idle')
      })
      .catch(() => {
        setStatus('failure')
      })
  }

  return (
    <Dialog onClose={onCancel}>
      <DialogCard>
        <DialogTitle>Nytt album</DialogTitle>

        {status === 'pending' ? (
          <TextLoader />
        ) : (
          <React.Fragment>
            {status === 'failure' && (
              <Error>Det oppstod dessverre en feil</Error>
            )}

            <h4>Velg en tittel på albumet eller lag din egen</h4>
            <List>
              {predefinedAlbumTitles.map((predefinedTitle) => (
                <ListItem key={predefinedTitle}>
                  <ListItemButton onClick={() => setName(predefinedTitle)}>
                    {predefinedTitle}
                  </ListItemButton>
                </ListItem>
              ))}
            </List>

            <FormField>
              <Label htmlFor="name">Navn</Label>
              <TextInput
                id="name"
                type="name"
                name="name"
                value={name}
                autoFocus
                onChange={({ target: { value } }) => setName(value)}
              />
            </FormField>

            <DialogActions>
              <SecondaryButton onClick={onCancel}>Avbryt</SecondaryButton>

              <PrimaryButton
                label="Lag"
                disabled={!name}
                onClick={createAlbum}
                isSmall={true}
              />
            </DialogActions>
          </React.Fragment>
        )}
      </DialogCard>
    </Dialog>
  )
}
