import styled from 'styled-components'
import { spacing } from '../../../styles/styles'
import React, { FunctionComponent, useState } from 'react'
import spinner from '../../../lottie/spinner.json'
import { Player } from '@lottiefiles/react-lottie-player'

type StyledImageProps = {
  minHeight?: number
  maxHeight?: number
}

export const ImageWrapper = styled.div`
  position: relative;
  padding-left: ${spacing.md};
  padding-right: ${spacing.md};
  width: 100%;
  overflow: hidden;
  min-height: ${(props: StyledImageProps) =>
    props.minHeight ? `${props.minHeight}px` : '100%'};
  min-height: ${(props: StyledImageProps) =>
    props.maxHeight ? `${props.maxHeight}px` : 'auto'};

  > img {
    width: 100%;
    height: auto;
    opacity: 0;
    transition: opacity 0.25s ease-out;

    &.imageLoaded {
      opacity: 1;
    }
  }
`

export const Loading = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

type ImageProps = {
  alt?: string
  source: any
  size: 'xsmall' | 'small' | 'medium' | 'large' | 'original'
  minHeight?: number
  maxHeight?: number
}

export const CardImage: FunctionComponent<ImageProps> = ({
  source,
  minHeight,
  maxHeight,
  alt,
  size,
}) => {
  const [imageLoading, setImageLoading] = useState<boolean>(true)

  return (
    <ImageWrapper minHeight={minHeight}>
      {imageLoading && (
        <Loading>
          <Player
            autoplay
            loop
            src={spinner}
            style={{ height: '9.375rem', width: '9.375rem' }}
          />
        </Loading>
      )}

      <img
        src={source[size]}
        alt={alt}
        className={imageLoading ? '' : 'imageLoaded'}
        onLoad={() => setImageLoading(false)}
      />
    </ImageWrapper>
  )
}
