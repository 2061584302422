import { authenticatedFetch } from './authenticatedFetch'
import { environment } from '../environments'
import { getBlob, loadExifData } from './file-helpers'

export function uploadFileToS3(url: string, data: any, contentType: string) {
  let options: any = {
    method: 'PUT',
    headers: {
      'Content-Type': contentType,
    },
    body: data,
  }

  return fetch(url, options).then(async (response) => {
    if (!response.ok) {
      throw new Error('Unable to upload file')
    }

    return await response.text()
  })
}

export function getUploadUrlForFile(
  file: File
): Promise<{ url: string; filename: string }> {
  return authenticatedFetch(
    `${environment.API_URL}/images/upload`,
    { method: 'POST' },
    { filename: file.name }
  )
}

export function createImage(data: {
  filename: string
  width: number
  height: number
  orientation: number
}): Promise<string> {
  return authenticatedFetch(
    `${environment.API_URL}/images`,
    { method: 'POST' },
    data
  ).then(({ id }) => id)
}

export async function extractImageDataFromFile(
  file: File
): Promise<
  (
    filename: string
  ) => Promise<{
    filename: string
    width: number
    height: number
    orientation: number
  }>
> {
  return async (filename: string) => {
    await loadExifData(file)

    const {
      Orientation,
      PixelXDimension,
      PixelYDimension,
      // @ts-ignore
    } = file.exifdata

    return {
      filename,
      width: PixelXDimension,
      height: PixelYDimension,
      orientation: Orientation,
    }
  }
}

export function addImageToAlbum(tagId: string) {
  return (imageId: string) =>
    authenticatedFetch(
      `${environment.API_URL}/images/${imageId}/tag`,
      { method: 'POST' },
      { tag_id: tagId }
    )
}

export function uploadBlob(
  filepath: string
): (data: { url: string; filename: string }) => Promise<string> {
  return async (data) => {
    const blob = await getBlob(filepath)
    return uploadFileToS3(data.url, blob, blob.type).then(() => data.filename)
  }
}
