import { Switch, Route, Redirect } from 'react-router-dom'
import { LoginPage } from './components/pages/login/LoginPage'
import React from 'react'

export const UnAuthenticatedApp = () => {
  return (
    <Switch>
      <Route path="/login">
        <LoginPage />
      </Route>
      <Redirect to="/login" />
    </Switch>
  )
}
