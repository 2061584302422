import React, { FunctionComponent, useEffect, useState } from 'react'
import styled from 'styled-components'
import { borderRadius } from '../../../styles/styles'

const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 50vw;
  border-top-right-radius: ${borderRadius};
  border-top-left-radius: ${borderRadius};
  overflow: hidden;
`

const Placeholder = styled.div`
  background: red;
`

const StyledImage = styled.img`
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`

type Props = {
  src: string
  alt: string
  maxHeight?: number
}

export const AlbumImage: FunctionComponent<Props> = ({
  src,
  alt,
  maxHeight,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(true)

  useEffect(() => {
    return () => setIsLoading(true)
  }, [])

  return (
    <ImageWrapper style={maxHeight ? { maxHeight: `${maxHeight}rem` } : {}}>
      {isLoading && <Placeholder />}
      <StyledImage
        style={isLoading ? { opacity: 0 } : { opacity: 1 }}
        onLoad={() => setIsLoading(false)}
        onError={() => setIsLoading(false)}
        src={src}
        alt={alt}
      />
    </ImageWrapper>
  )
}
