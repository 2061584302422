import React from 'react'
import { spacing } from '../../styles/styles'
import { Card } from './post/PostCard'
import ContentLoader from 'react-content-loader'

export const TextLoader = () => {
  return (
    <Card aria-label="Vennligst vent..." style={{ paddingLeft: spacing.md }}>
      <ContentLoader
        speed={2}
        width={400}
        height={80}
        viewBox="0 0 400 80"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
      >
        <rect x="0" y="20" rx="5" ry="5" width="100" height="10" />
        <rect x="0" y="40" rx="5" ry="5" width="400" height="10" />
        <rect x="0" y="60" rx="5" ry="5" width="250" height="10" />
      </ContentLoader>
    </Card>
  )
}
