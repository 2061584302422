import React, { FunctionComponent, useState } from 'react'
import styled from 'styled-components'
import { borderRadius, colors } from '../../../styles/styles'

type MediaProps = {
  media: any
  onClick: () => void
}

const MediaWrapper = styled.div`
  border: 1px solid ${colors.black5};

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: ${borderRadius};
  }
`

const Error = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Akkurat;
  height: 100%;
  background: ${colors.black5};
`

export const TimelineMedia: FunctionComponent<MediaProps> = ({
  media,
  onClick,
}) => {
  const [error, setError] = useState<string>('')

  const onError = () => setError('Det oppstod en feil ved lasting av bilde')

  return (
    <MediaWrapper style={{ height: window.innerWidth / 2 }} onClick={onClick}>
      {error ? (
        <Error>{error}</Error>
      ) : media.mp4_urls ? (
        <img alt="Bilde" src={media.thumbnail.small} onError={onError} />
      ) : (
        <img alt="Bilde" src={media.small} onError={onError} />
      )}
    </MediaWrapper>
  )
}
