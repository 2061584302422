import React from 'react'
import { spacing } from '../../styles/styles'
import ContentLoader from 'react-content-loader'
import { Card } from './post/PostCard'

export const ListItemLoader = () => {
  return (
    <Card aria-label="Vennligst vent..." style={{ paddingLeft: spacing.md }}>
      <ContentLoader
        speed={2}
        width={400}
        height={70}
        viewBox="0 0 400 70"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
      >
        <circle cx="29" cy="39" r="27" />
        <rect x="67" y="21" rx="5" ry="5" width="220" height="10" />
        <circle cx="10" cy="50" r="8" />
        <rect x="69" y="47" rx="5" ry="5" width="139" height="10" />
      </ContentLoader>
    </Card>
  )
}
