import styled from 'styled-components'
import { Container } from '../components/styled/Layout'
import {
  breakpoints,
  colors,
  navBarHeightLarge,
  navBarHeightSmall,
  spacing,
} from '../styles/styles'

export const NavContainer = styled(Container)`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
`

export const Spacer = styled.div`
  flex: 1;

  @media only screen and (min-width: ${breakpoints.MEDIUM}) {
    display: none;
  }
`

export const NavMenu = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const NavItem = styled.li`
  flex: 1;
  text-align: right;

  @media only screen and (min-width: ${breakpoints.MEDIUM}) {
    text-align: left;
  }
`

export const Nav = styled.nav`
  position: fixed;
  width: 100%;
  padding: 0 ${spacing.md};
  background: white;
  z-index: 1;
  height: ${navBarHeightSmall};
  border-bottom: 1px solid ${colors.black5};

  @media only screen and (min-width: ${breakpoints.MEDIUM}) {
    height: ${navBarHeightLarge};
    border-bottom: none;
  }
`

export const NavBrand = styled.h2`
  margin: 0 ${spacing.md} 0 0;
  font-size: 1.5rem;
  flex: 1;
  text-align: center;

  @media only screen and (min-width: ${breakpoints.MEDIUM}) {
    margin: 0 ${spacing.xl} 0 0;
    font-size: 2rem;
    flex: none;
    text-align: left;
  }
`
