import React, { FunctionComponent } from 'react'
import { SmallScreenNavbar } from './navigation/SmallScreenNavbar'
import { TabBar } from './navigation/TabBar'
import styled from 'styled-components'
import { navBarHeightSmall } from './styles/styles'

const Screen = styled.div`
  height: ${window.innerHeight}px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
`

const View = styled.div`
  flex: 1;
  overflow-y: scroll;
  -webkit-overflow-scrolling: auto;
`

type Props = {
  currentPathIsRoot: boolean
}

export const SmallScreenView: FunctionComponent<Props> = ({
  children,
  currentPathIsRoot,
}) => {
  const viewStyles = { paddingTop: navBarHeightSmall }

  return (
    <Screen>
      <SmallScreenNavbar currentPathIsRoot={currentPathIsRoot} />

      <View
        id="ScrollView" // Needed for infinite scroll
        style={
          currentPathIsRoot
            ? { ...viewStyles, paddingBottom: navBarHeightSmall }
            : viewStyles
        }
      >
        {children}
      </View>

      {currentPathIsRoot && <TabBar />}
    </Screen>
  )
}
