import React, { FunctionComponent } from 'react'
import { colors, spacing } from '../styles/styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import styled from 'styled-components'
import { Nav, NavBrand, NavContainer, Spacer } from './NavStyles'
import { useHistory } from 'react-router-dom'
import { useNavBar } from './NavBarProvider'
import { SecondaryButton } from '../components/shared/Buttons'

const NavBackButton = styled.button`
  flex: 1;
  padding: ${spacing.sm} 0;
  font-size: inherit;
  border: none;
  background: white;
  text-align: left;

  &:active {
    background: ${colors.black20};
  }

  svg {
    margin-right: ${spacing.sm};
  }
`

const NavBarButtons = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
`

type Props = {
  currentPathIsRoot: boolean
}

export const SmallScreenNavbar: FunctionComponent<Props> = ({
  currentPathIsRoot,
}) => {
  const history = useHistory()
  const { title, rightButtons } = useNavBar()

  return (
    <Nav>
      <NavContainer>
        {!currentPathIsRoot ? (
          <NavBackButton onClick={() => history.goBack()}>
            <FontAwesomeIcon icon={faArrowLeft} />
            Tilbake
          </NavBackButton>
        ) : (
          <Spacer />
        )}

        <NavBrand>{title}</NavBrand>

        {rightButtons ? (
          <NavBarButtons>
            {rightButtons.map(({ label, onClick }) => (
              <SecondaryButton key={label} onClick={onClick}>
                {label}
              </SecondaryButton>
            ))}
          </NavBarButtons>
        ) : (
          <Spacer />
        )}
      </NavContainer>
    </Nav>
  )
}
