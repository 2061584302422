import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { borderRadius, colors, spacing } from '../../styles/styles'

type PrimaryButtonProps = {
  size: 'small' | 'large'
}

const PrimaryButtonStyled = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  min-width: ${({ size }: PrimaryButtonProps) =>
    size === 'small' ? '' : ' 300px'};
  padding: 0 ${spacing.lg};
  height: ${({ size }: PrimaryButtonProps) =>
    size === 'small' ? '2.375rem' : '3rem'};
  color: white;
  font-size: inherit;
  background: ${colors.safeDark};
  border: none;
  border-radius: ${borderRadius};
  text-transform: uppercase;

  &:disabled {
    background: ${colors.black20};
  }
`

const Icon = styled.img`
  position: absolute;
  right: ${spacing.lg};
`

type Props = {
  label?: string
  icon?: any
  onClick: () => void
  isSmall?: boolean
  disabled?: boolean
}

export const PrimaryButton: FunctionComponent<Props> = ({
  label,
  icon,
  onClick,
  isSmall,
  disabled,
}) => {
  return (
    <PrimaryButtonStyled
      type="button"
      onClick={onClick}
      size={isSmall ? 'small' : 'large'}
      disabled={disabled}
    >
      {label && label} {icon && <Icon src={icon} />}
    </PrimaryButtonStyled>
  )
}

export const SecondaryButton = styled.button`
  padding: ${spacing.sm} ${spacing.md};
  border-radius: ${borderRadius};
  border: 2px solid ${colors.black80};
  font-size: inherit;
  background: white;
`

export const DangerButton = styled.button`
  padding: ${spacing.sm} ${spacing.md};
  border-radius: ${borderRadius};
  font-size: inherit;
  border: none;
  color: white;
  background: ${colors.error};
`

export const DangerOutlinedButton = styled.button`
  padding: ${spacing.sm} ${spacing.md};
  border-radius: ${borderRadius};
  font-size: inherit;
  border: 2px solid ${colors.error};
  color: ${colors.error};
  background: white;
`
