import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { PostDetailPage } from '../components/pages/book/PostDetailPage'
import { SettingsPage } from '../components/pages/profile/SettingsPage'
import { ProfilePage } from '../components/pages/profile/ProfilePage'
import { AlbumPage } from '../components/pages/album/AlbumPage'
import { AllAlbumsPage } from '../components/pages/album/AllAlbumsPage'
import { ContactPage } from '../components/pages/contacts/ContactPage'
import { BookPage } from '../components/pages/book/BookPage'
import { TimelinePage } from '../components/pages/timeline/TimelinePage'

export const Routes = () => {
  return (
    <Switch>
      <Route path="/post/:postId">
        <PostDetailPage />
      </Route>
      <Route path="/book">
        <BookPage />
      </Route>
      <Route path="/profile/settings">
        <SettingsPage />
      </Route>
      <Route path="/profile">
        <ProfilePage />
      </Route>
      <Route path="/album/:tagId">
        <AlbumPage />
      </Route>
      <Route path="/album">
        <AllAlbumsPage />
      </Route>
      <Route path="/contacts">
        <ContactPage />
      </Route>
      <Route path="/timeline">
        <TimelinePage />
      </Route>
      <Redirect to="/book" />
    </Switch>
  )
}
