import React, {
  createContext,
  FunctionComponent,
  useEffect,
  useState,
} from 'react'
import { authenticatedFetch } from '../lib/authenticatedFetch'
import { environment } from '../environments'

type Auth = {
  authToken: string
  authUser?: { id: string; first_name: string; last_name: string; image: any }
  settings: any
  updateSettings: (settings: any) => void
  reqStatus: 'idle' | 'pending' | 'failure'
  login(user: { email: string; password: string }): Promise<void>
  logout(): void
}

export const AuthContext = createContext<Auth>({
  authToken: '',
  authUser: undefined,
  settings: undefined,
  updateSettings: (settings: any) => {},
  reqStatus: 'idle',
  login: (user: { email: string; password: string }): Promise<void> =>
    Promise.resolve(),
  logout: () => {},
})

export const AuthProvider: FunctionComponent = ({ children }) => {
  const [reqStatus, setReqStatus] = useState<'idle' | 'pending' | 'failure'>(
    'pending'
  )
  const [authToken, setAuthToken] = useState<any>(
    localStorage.getItem('jodabook-token')
  )
  const [authUser, setAuthUser] = useState<any | null>(null)
  const [settings, setSettings] = useState<any | undefined>(undefined)

  const login = (user: { email: string; password: string }): Promise<void> => {
    setReqStatus('pending')

    return fetch(`${environment.API_URL}/auth/service-receiver`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(user),
    })
      .then(async (response) => {
        if (!response.ok) {
          setReqStatus('failure')
          const res = await response.json()
          throw new Error(res.message)
        }

        return response.json()
      })
      .then(({ token }) => {
        setReqStatus('idle')
        localStorage.setItem('jodabook-token', token)
        setAuthToken(token)

        // @ts-ignore
        if (window.ReactNativeWebView) {
          // @ts-ignore
          window?.ReactNativeWebView.postMessage(
            JSON.stringify({
              type: 'AUTH_SUCCESS',
              payload: token,
            })
          )
        }
      })
      .catch((error) => {
        setReqStatus('idle')
        throw error
      })
  }

  const logout = () => {
    localStorage.setItem('jodabook-token', '')
    setAuthToken(null)
    setAuthUser(null)
  }

  useEffect(() => {
    if (authToken) {
      setReqStatus('pending')

      authenticatedFetch(`${environment.API_URL}/auth`).then((response) => {
        setAuthUser(response)
        setReqStatus('idle')
      })
    } else {
      setReqStatus('idle')
    }
  }, [authToken])

  useEffect(() => {
    if (!authUser?.id) {
      return
    }

    authenticatedFetch(
      `${environment.API_URL}/users/${authUser?.id}/settings`
    ).then((response) => {
      setSettings(response)
    })
  }, [authUser])

  const updateSettings = (settings: any) => {
    authenticatedFetch(
      `${environment.API_URL}/users/${authUser?.id}/settings`,
      { method: 'PATCH' },
      settings
    ).then((response) => {
      setSettings(response)
    })
  }

  return (
    <AuthContext.Provider
      value={{
        authToken,
        authUser,
        settings,
        updateSettings,
        reqStatus,
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
