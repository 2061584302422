import React, { useCallback, useEffect, useState } from 'react'
import { useNavBar } from '../../../navigation/NavBarProvider'
import { authenticatedFetch } from '../../../lib/authenticatedFetch'
import { environment } from '../../../environments'
import { PageContainer } from '../../styled/Layout'
import { PostLoader } from '../../shared/post/PostLoader'
import { Post } from '../../shared/post/Post'
import { FeedHeader } from './FeedHeader'
import { ConfirmDialog } from '../../shared/modal/ConfirmDialog'
import { isSmallScreen, spacing } from '../../../styles/styles'
import { useHistory } from 'react-router-dom'

export const BookPage = () => {
  const history = useHistory()
  const { setTitle, setRightButtons } = useNavBar()
  const [status, setStatus] = useState<'idle' | 'pending' | 'failure'>('idle')
  const [posts, setPosts] = useState<any[] | null>(null)
  const [, setCount] = useState<number>(0)
  const [nextUrl, setNextUrl] = useState<string>('')
  const [deletingPostId, setDeletingPostId] = useState<string>('')
  const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false)

  useEffect(() => {
    setTitle('Boka')
    setRightButtons([
      { label: 'Tidslinje', onClick: () => history.push('/timeline') },
    ])

    return () => {
      setTitle('')
      setRightButtons([])
    }
  }, [setTitle, setRightButtons, history])

  useEffect(() => {
    authenticatedFetch(`${environment.API_URL}/posts`)
      .then(({ results, count, next }) => {
        setStatus('idle')
        setPosts(results)
        setCount(count)
        setNextUrl(next)
      })
      .catch(() => {
        setStatus('failure')
      })
  }, [])

  const loadMore = useCallback(() => {
    if (nextUrl) {
      setStatus('pending')

      authenticatedFetch(nextUrl)
        .then(({ results, count, next }) => {
          setStatus('idle')
          // @ts-ignore
          setPosts((prevPostState) => [...prevPostState, ...results])
          setCount(count)
          setNextUrl(next)
        })
        .catch(() => {
          setStatus('failure')
        })
    }
  }, [nextUrl])

  useEffect(() => {
    const smallScreen = isSmallScreen()

    const view: any = smallScreen
      ? document.getElementById('ScrollView')
      : window

    if (view) {
      const handler = () => {
        const scrollTop = view.scrollTop
        const offsetHeight = view.offsetHeight
        const contentHeight = view.scrollHeight - offsetHeight

        const hasScrolledToBottom = smallScreen
          ? contentHeight <= scrollTop
          : window.innerHeight + window.scrollY >= document.body.scrollHeight

        if (hasScrolledToBottom && status !== 'pending') {
          loadMore()
        }
      }

      view.addEventListener('scroll', handler)

      return () => view.removeEventListener('scroll', handler)
    }
  }, [status, loadMore])

  const deletePost = () => {
    setShowConfirmDelete(false)

    authenticatedFetch(`${environment.API_URL}/posts/${deletingPostId}`, {
      method: 'DELETE',
    })
      .then(() => {
        setPosts(posts!.filter((p) => p.id !== deletingPostId))
        setDeletingPostId('')
      })
      .catch(() => {})
  }

  return (
    <PageContainer>
      <FeedHeader />

      {status === 'pending' && !posts ? (
        <PostLoader />
      ) : status === 'failure' ? (
        <div>Det oppstod dessverre en feil</div>
      ) : (
        <React.Fragment>
          {posts?.map((post: any, i: any) =>
            deletingPostId === post.id ? (
              <PostLoader key={`loading-${i}`} />
            ) : post.need_moderation ? null : (
              <Post
                key={`p-${i}`}
                post={post}
                onDelete={() => {
                  setShowConfirmDelete(true)
                  setDeletingPostId(post.id)
                }}
                onVideoFetchingCompleted={(result: any) => setPosts(result)}
              />
            )
          )}

          {status === 'pending' && <PostLoader />}

          {nextUrl === null && (
            <div
              style={{
                padding: `${spacing.sm} ${spacing.md} ${spacing.lg}`,
                textAlign: 'center',
              }}
            >
              Ingen flere innlegg
            </div>
          )}
        </React.Fragment>
      )}

      <ConfirmDialog
        show={showConfirmDelete}
        title="Slette innlegg?"
        onClose={() => {
          setShowConfirmDelete(false)
          setDeletingPostId('')
        }}
        onConfirm={deletePost}
        onCancel={() => {
          setShowConfirmDelete(false)
          setDeletingPostId('')
        }}
      />
    </PageContainer>
  )
}
