import React, { useContext } from 'react'
import { Avatar } from '../../shared/Avatar'
import { spacing } from '../../../styles/styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCamera, faImages } from '@fortawesome/free-solid-svg-icons'
import styled from 'styled-components'
import { Card } from '../../shared/post/PostCard'
import { SecondaryButton } from '../../shared/Buttons'
import { AuthContext } from '../../../contexts/AuthContext'

const FeedHeaderCard = styled(Card)`
  display: flex;
  padding: ${spacing.md};
  margin-left: auto;
  margin-right: auto;
  max-width: 35rem;
`

const FeedHeaderAvatar = styled(Avatar)`
  margin-right: ${spacing.md};
`

const Greeting = styled.div`
  flex: 1;
`

const FeedHeaderCardButtons = styled.div`
  margin-top: ${spacing.md};
  display: flex;
  justify-content: space-between;
`

const FeedHeaderCardButton = styled(SecondaryButton)`
  flex: 1;

  &:not(:last-child) {
    margin-right: ${spacing.sm};
  }
`

export const FeedHeader = () => {
  const { authUser } = useContext(AuthContext)
  const { settings } = useContext(AuthContext)

  // @ts-ignore
  const isNative = window?.ReactNativeWebView
  const isTurnedOn = settings?.jodabook_publish

  const openCamera = () => {
    if (isNative) {
      // @ts-ignore
      window?.ReactNativeWebView.postMessage(
        JSON.stringify({
          type: 'CAMERA_OPEN',
          payload: '',
        })
      )
    }
  }

  const openGallery = () => {
    if (isNative) {
      // @ts-ignore
      window?.ReactNativeWebView.postMessage(
        JSON.stringify({
          type: 'GALLERY_OPEN',
          payload: '',
        })
      )
    }
  }

  return isNative && isTurnedOn ? (
    <FeedHeaderCard>
      <FeedHeaderAvatar
        image={authUser?.image}
        size="small"
        alt={`Bilde av ${authUser?.first_name}`}
      />
      <Greeting>
        Hei {authUser?.first_name}!<br />
        Har du noe å dele i dag?
        {isNative && (
          <FeedHeaderCardButtons>
            <FeedHeaderCardButton onClick={openCamera}>
              <FontAwesomeIcon icon={faCamera} />
            </FeedHeaderCardButton>

            <FeedHeaderCardButton onClick={openGallery}>
              <FontAwesomeIcon icon={faImages} />
            </FeedHeaderCardButton>
          </FeedHeaderCardButtons>
        )}
      </Greeting>
    </FeedHeaderCard>
  ) : null
}
