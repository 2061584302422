import React, { useEffect, useState } from 'react'
import { isSmallScreen, spacing } from '../../../styles/styles'
import { AlbumPageLargeScreen } from './AlbumPageLargeScreen'
import { AlbumPageSmallScreen } from './AlbumPageSmallScreen'
import { authenticatedFetch } from '../../../lib/authenticatedFetch'
import { environment } from '../../../environments'
import { useHistory, useParams } from 'react-router-dom'
import { ConfirmDialog } from '../../shared/modal/ConfirmDialog'
import { LargeAlbumView } from '../../shared/LargeAlbumView'
import { AddImagesToAlbum } from '../../shared/AddImagesToAlbum'
import { getAlbumImages } from '../../../lib/album.service'

export const AlbumPage = () => {
  const history = useHistory()
  const { tagId } = useParams<{ tagId: string }>()
  const [status, setStatus] = useState<'idle' | 'pending' | 'failure'>(
    'pending'
  )
  const [tag, setTag] = useState<any | null>(null)
  const [images, setImages] = useState<any[]>([])
  const [imageToRemove, setImageToRemove] = useState<any | null>(null)
  const [confirmDelete, setConfirmDelete] = useState(false)
  const [imageIndex, setImageIndex] = useState<number | null>(null)

  // @ts-ignore
  const isNative = window?.ReactNativeWebView

  useEffect(() => {
    Promise.all([
      getAlbumImages(tagId),
      authenticatedFetch(`${environment.API_URL}/tags/${tagId}`),
    ])
      .then((responses) => {
        setImages(responses[0].results)
        setTag(responses[1])
        setStatus('idle')
      })
      .catch(() => {
        setStatus('failure')
      })
  }, [tagId])

  const onImagesAdded = () => {
    getAlbumImages(tagId)
      .then(({ results }) => {
        setImages(results)
      })
      .catch(() => {
        setStatus('failure')
      })
  }

  const deleteAlbum = () => {
    setStatus('pending')

    authenticatedFetch(`${environment.API_URL}/tags/${tagId}`, {
      method: 'DELETE',
    })
      .then(() => history.push(`/album`))
      .catch(() => setStatus('failure'))
  }

  const removeImageFromAlbum = () => {
    const imageId = imageToRemove.id
    setImageToRemove(null)

    setStatus('pending')

    authenticatedFetch(
      `${environment.API_URL}/images/${imageId}/tag`,
      {
        method: 'DELETE',
      },
      { tag_id: tagId }
    )
      .then(() => {
        setStatus('idle')
        setImages(images.filter((image) => image.id !== imageId))
      })
      .catch(() => setStatus('failure'))
  }

  const addImages = () => {
    if (isNative) {
      // @ts-ignore
      window?.ReactNativeWebView.postMessage(
        JSON.stringify({
          type: 'ADD_IMAGES_TO_ALBUM',
          payload: { tagId },
        })
      )
    }
  }

  return (
    <React.Fragment>
      {isSmallScreen() ? (
        <AlbumPageSmallScreen
          album={tag}
          deleteAlbum={() => setConfirmDelete(true)}
          status={status}
          addImages={addImages}
          images={images}
          isNative={isNative}
          removeImage={(image) => setImageToRemove(image)}
          showLargeImage={(imageIndex) => setImageIndex(imageIndex)}
          addImagesComponent={
            <div
              style={{
                position: 'absolute',
                bottom: spacing.md,
                width: '100%',
                padding: `0 ${spacing.md}`,
              }}
            >
              <AddImagesToAlbum albumId={tagId} onImagesAdded={onImagesAdded} />
            </div>
          }
        />
      ) : (
        <AlbumPageLargeScreen
          album={tag}
          deleteAlbum={() => setConfirmDelete(true)}
          status={status}
          addImages={addImages}
          images={images}
          isNative={isNative}
          removeImage={(image) => setImageToRemove(image)}
          showLargeImage={(imageIndex) => setImageIndex(imageIndex)}
          addImagesComponent={
            <AddImagesToAlbum albumId={tagId} onImagesAdded={onImagesAdded} />
          }
        />
      )}

      <ConfirmDialog
        show={Boolean(confirmDelete)}
        title="Slette album?"
        description="Dette sletter ikke bildene, kun samlingen."
        confirmButtonText="Slett"
        onClose={() => setConfirmDelete(false)}
        onConfirm={deleteAlbum}
        onCancel={() => setConfirmDelete(false)}
      />

      <ConfirmDialog
        show={Boolean(imageToRemove)}
        title="Fjerne bilde fra album?"
        onClose={() => setImageToRemove(null)}
        onConfirm={removeImageFromAlbum}
        onCancel={() => setImageToRemove(null)}
      />

      {imageIndex !== null ? (
        <LargeAlbumView
          initialIndex={imageIndex}
          images={images}
          onClose={() => setImageIndex(null)}
        />
      ) : null}
    </React.Fragment>
  )
}
