import { Avatar } from '../Avatar'
import { SecondaryButton } from '../Buttons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { colors, spacing } from '../../../styles/styles'

const Header = styled.div`
  display: flex;
  padding: ${spacing.md};
  align-items: center;
  justify-content: space-between;
`

const Owner = styled.div`
  display: flex;
  align-items: center;
  font-weight: bold;
  color: ${colors.jodaDark};
  gap: ${spacing.sm};
`

type Props = {
  post: any
  isCreator: boolean
  onDelete: () => void
}

export const CardHeader: FunctionComponent<Props> = ({
  post,
  isCreator,
  onDelete,
}) => {
  return (
    <Header>
      <Owner>
        <Avatar
          alt={`Bilde av${post.created_by.first_name} ${post.created_by.last_name}`}
          image={post.created_by.image}
          size="small"
        />
        {post.created_by.first_name} {post.created_by.last_name}
      </Owner>

      {isCreator && (
        <SecondaryButton onClick={onDelete} aria-label="Slett">
          <FontAwesomeIcon icon={faTrash} />
        </SecondaryButton>
      )}
    </Header>
  )
}
