import { colors } from '../../../styles/styles'
import { DangerButton } from '../../shared/Buttons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import React, { FunctionComponent, useState } from 'react'
import { Player } from '@lottiefiles/react-lottie-player'
import spinner from '../../../lottie/spinner.json'
import { Loading } from '../../shared/post/CardImage'

type Props = {
  selected: boolean
  onSelect: () => void
  isEditMode: boolean
  image: any
  onRemoveImage: () => void
}

export const GalleryImage: FunctionComponent<Props> = ({
  selected,
  onSelect,
  isEditMode,
  image,
  onRemoveImage,
}) => {
  const [loaded, setLoaded] = useState(false)

  return (
    <div
      style={{
        position: 'relative',
        minHeight: '5rem',
        background: colors.black5,
      }}
    >
      {isEditMode && (
        <DangerButton
          onClick={onRemoveImage}
          style={{
            position: 'absolute',
            top: 0,
            right: 0,
          }}
        >
          <FontAwesomeIcon icon={faTrash} />
        </DangerButton>
      )}

      {!loaded && (
        <Loading>
          <Player
            autoplay
            loop
            src={spinner}
            style={{ height: '4rem', width: '4rem' }}
          />
        </Loading>
      )}

      <img
        className={selected ? 'selected' : ''}
        onClick={onSelect}
        src={image.small}
        alt="Bilde"
        onLoad={() => setLoaded(true)}
      />
    </div>
  )
}
