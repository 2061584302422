import { authenticatedFetch } from './authenticatedFetch'
import { environment } from '../environments'

export const getProfileAlbumId = (name: string) => {
  return authenticatedFetch(`${environment.API_URL}/tags`).then((tags) => {
    const profileAlbum = tags.find((t: any) => t.emotion === 'ME')

    if (profileAlbum) {
      return profileAlbum.id
    } else {
      return authenticatedFetch(
        `${environment.API_URL}/tags`,
        { method: 'POST' },
        { name, emotion: 'ME' }
      ).then(({ id }) => id)
    }
  })
}

export function getAlbumImages(id: string) {
  return authenticatedFetch(`${environment.API_URL}/images/tag/${id}`)
}
