import React, { useContext, useEffect, useState } from 'react'
import { PageContainer } from '../../styled/Layout'
import { Avatar } from '../../shared/Avatar'
import { AuthContext } from '../../../contexts/AuthContext'
import { Card } from '../../shared/post/PostCard'
import {
  borderRadius,
  breakpoints,
  colors,
  spacing,
} from '../../../styles/styles'
import styled from 'styled-components'
import { authenticatedFetch } from '../../../lib/authenticatedFetch'
import { ErrorMessage } from '../../styled/Message'
import { environment } from '../../../environments'
import { TextLoader } from '../../shared/TextLoader'
import { ProfileImages } from './ProfileImages'
import { Link, useHistory } from 'react-router-dom'
import { useNavBar } from '../../../navigation/NavBarProvider'

const ProfileCard = styled(Card)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin-top: 150px;
  padding: ${spacing.xl};

  h1 {
    margin: 0;
  }

  @media only screen and (min-width: ${breakpoints.MEDIUM}) {
    position: relative;
  }
`

const SettingsLink = styled(Link)`
  display: none;

  @media only screen and (min-width: ${breakpoints.MEDIUM}) {
    display: inline-block;
    position: absolute;
    color: white;
    top: ${spacing.md};
    right: ${spacing.md};
    padding: ${spacing.sm} ${spacing.md};
    background: ${colors.safeDark};
    text-decoration: none;
    border-radius: ${borderRadius};

    &:active {
      background: ${colors.safeLight};
    }
  }
`

export const ProfilePage = () => {
  const history = useHistory()
  const { setTitle, setRightButtons } = useNavBar()
  const { authUser } = useContext(AuthContext)
  const [status, setStatus] = useState<'idle' | 'pending' | 'failure'>('idle')
  const [profile, setProfile] = useState<any>({})

  useEffect(() => {
    if (authUser?.id) {
      setStatus('pending')

      authenticatedFetch(`${environment.API_URL}/users/${authUser.id}/profile`)
        .then((profile) => {
          setStatus('idle')
          setProfile(profile)
        })
        .catch(() => setStatus('failure'))
    }
  }, [authUser])

  useEffect(() => setTitle('Om meg'), [setTitle])
  useEffect(() => {
    setRightButtons([
      {
        label: 'Innstillinger',
        onClick: () => history.push('/profile/settings'),
      },
    ])

    return () => setRightButtons([])
  }, [setRightButtons, history])

  const addProfileImage = () => {
    // @ts-ignore
    const isNative = window?.ReactNativeWebView

    if (isNative) {
      // @ts-ignore
      window?.ReactNativeWebView.postMessage(
        JSON.stringify({
          type: 'ADD_PROFILE_IMAGE',
          payload: {},
        })
      )
    }
  }

  if (authUser) {
    return (
      <PageContainer>
        <ProfileCard>
          <SettingsLink to="/profile/settings">Innstillinger</SettingsLink>

          <Avatar
            onClick={addProfileImage}
            alt={`Bilde av ${authUser.first_name} ${authUser.last_name}`}
            style={{ marginTop: '-200px', marginBottom: spacing.md }}
            image={authUser.image}
            size="medium"
          />

          <h1 style={{ textAlign: 'center' }}>
            {authUser.first_name} {authUser.last_name}
          </h1>

          {status === 'failure' && (
            <ErrorMessage>Det oppstod dessverre en feil.</ErrorMessage>
          )}

          {status === 'pending' ? <TextLoader /> : <p>{profile.description}</p>}

          <ProfileImages />
        </ProfileCard>
      </PageContainer>
    )
  }

  return <div>Laster...</div>
}
