import React, { FunctionComponent, useState } from 'react'
import styled from 'styled-components'
import { Avatar } from '../Avatar'
import { breakpoints, colors, spacing } from '../../../styles/styles'
import { Card } from '../post/PostCard'
import { PrimaryButton } from '../Buttons'
import { useVideoCalls } from '../../../contexts/VideoCallsProvider'
import { authenticatedFetch } from '../../../lib/authenticatedFetch'
import { environment } from '../../../environments'

const ListItem = styled(Card)`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  column-gap: 1rem;
  align-items: center;
  justify-content: space-between;
  padding: ${spacing.md};
  border-bottom: 1px solid ${colors.black5};

  @media only screen and (min-width: ${breakpoints.MEDIUM}) {
    grid-template-columns: repeat(6, 1fr);
  }
`

const Title = styled.div`
  grid-column-start: 2;
  grid-column: span 3 / span 3;
`

const Error = styled.div`
  color: ${colors.error};
`

type Props = {
  data: any
}

export const VideoContactListItem: FunctionComponent<Props> = ({ data }) => {
  const [status, setStatus] = useState<'idle' | 'pending' | 'failure'>('idle')
  const { onVideoCallInitiated } = useVideoCalls()

  const initiateCall = (userId: string) => {
    setStatus('pending')

    authenticatedFetch(
      `${environment.API_URL}/video-calls`,
      { method: 'POST' },
      {
        user_id: userId,
      }
    )
      .then((response) => {
        setStatus('pending')
        // @ts-ignore
        const isNative = window?.ReactNativeWebView
        if (isNative) {
          // @ts-ignore
          window?.ReactNativeWebView.postMessage(
            JSON.stringify({
              type: 'SENDING_VIDEO_CALL',
              payload: response,
            })
          )
        }
        onVideoCallInitiated(response)
      })
      .catch(() => {
        setStatus('failure')
      })
  }

  return (
    <li>
      <ListItem onClick={() => {}}>
        <Avatar
          size="small"
          image={data.image}
          alt={`Bilde av ${data.first_name}`}
          style={{
            gridColumnStart: 1,
            gridColumn: 'span 1 / span 1',
          }}
        />
        <Title>
          <div>
            {data.first_name} {data.last_name}
          </div>
          {status === 'failure' && <Error>Det oppstod dessverre en feil</Error>}
        </Title>
        <div
          style={{
            gridColumnStart: 5,
            gridColumn: 'span 2 / span 2',
            justifySelf: 'flex-end',
          }}
        >
          <PrimaryButton
            disabled={status === 'pending'}
            label="Ring"
            onClick={() => initiateCall(data.id)}
            isSmall={true}
          />
        </div>
      </ListItem>
    </li>
  )
}
