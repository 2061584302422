import styled from 'styled-components'
import { breakpoints, navBarHeightLarge, spacing } from '../../styles/styles'

export const Container = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 960px;
`

export const PageContainer = styled(Container)`
  @media only screen and (min-width: ${breakpoints.MEDIUM}) {
    padding: calc(${navBarHeightLarge} + ${spacing.md}) 0 ${spacing.xl};
  }
`
