import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { colors, spacing } from '../../../styles/styles'
import { Card } from '../../shared/post/PostCard'
import { Avatar } from '../../shared/Avatar'
import { SpeechButton } from '../../shared/SpeechButton'

const ListItem = styled(Card)`
  display: flex;
  align-items: center;
  padding: ${spacing.md};
  border-bottom: 1px solid ${colors.black5};
`

const Title = styled.small`
  align-self: flex-start;
  font-weight: bold;
  flex: 1;
`

type Props = {
  data: any
}

export const CommentListItem: FunctionComponent<Props> = ({ data }) => {
  return (
    <li>
      <ListItem onClick={() => {}}>
        <Avatar
          size="small"
          image={data.created_by.image}
          alt={`Bilde av ${data.created_by.first_name}`}
        />
        <div style={{ flex: 1 }}>
          <Title>{data.created_by.first_name}</Title>
          <div>{data.text}</div>
        </div>
        <SpeechButton text={data.text} />
      </ListItem>
    </li>
  )
}
