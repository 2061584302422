import React, {
  FunctionComponent,
  useCallback,
  useContext,
  useState,
} from 'react'
import styled from 'styled-components'
import { colors, spacing } from '../../../styles/styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeart } from '@fortawesome/free-solid-svg-icons'
import { AuthContext } from '../../../contexts/AuthContext'
import { authenticatedFetch } from '../../../lib/authenticatedFetch'
import { environment } from '../../../environments'

type Cared = {
  id: string
  first_name: string
  last_name: string
}

const Reactions = styled.div`
  display: flex;
  padding: ${spacing.sm} ${spacing.md};
  align-items: center;
`

const ButtonStyled = styled.button`
  padding: ${spacing.md} ${spacing.md} ${spacing.md} 0;
  border: none;
  background: transparent;
`

type Props = {
  postId: string
  reactions: Cared[]
}

export const PostReactions: FunctionComponent<Props> = ({
  postId,
  reactions,
}) => {
  const { authUser } = useContext(AuthContext)
  const [cared, setCared] = useState<any[]>(reactions)

  const hasCared = cared.some((user) => user.id === authUser?.id)
  const names = cared.map((user) => user.first_name)

  const addUserToCared = useCallback(() => {
    setCared((prev) => [
      ...prev,
      {
        id: authUser?.id,
        first_name: authUser?.first_name,
        last_name: authUser?.last_name,
      },
    ])
  }, [authUser])

  const removeUserFromCared = useCallback(() => {
    setCared((prev) => prev.filter((user) => user.id !== authUser?.id))
  }, [authUser])

  const addHeart = () => {
    addUserToCared()

    authenticatedFetch(`${environment.API_URL}/posts/${postId}/cared`, {
      method: 'POST',
    }).catch(removeUserFromCared)
  }

  const removeHeart = () => {
    removeUserFromCared()

    authenticatedFetch(`${environment.API_URL}/posts/${postId}/cared`, {
      method: 'DELETE',
    }).catch(addUserToCared)
  }

  const toggleHeart = hasCared ? removeHeart : addHeart

  return (
    <React.Fragment>
      <div
        style={{
          marginLeft: spacing.md,
          marginBottom: spacing.sm,
        }}
      >
        <ButtonStyled onClick={toggleHeart}>
          <FontAwesomeIcon
            style={{
              color: hasCared ? colors.closeLight : colors.black,
              marginRight: spacing.sm,
              fontSize: '1.3rem',
            }}
            icon={faHeart}
          />
        </ButtonStyled>
      </div>

      {cared.length > 0 ? (
        <Reactions>
          <FontAwesomeIcon
            style={{ color: colors.closeLight, marginRight: spacing.sm }}
            icon={faHeart}
          />
          {names.join(', ')}
        </Reactions>
      ) : null}
    </React.Fragment>
  )
}
