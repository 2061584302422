import styled from 'styled-components'
import { Card } from '../post/PostCard'
import { breakpoints, spacing } from '../../../styles/styles'

export const DialogCard = styled(Card)`
  position: fixed;
  width: 100vw;
  bottom: 0;
  padding: ${spacing.lg};
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;

  @media only screen and (min-width: ${breakpoints.LARGE}) {
    position: relative;
    top: 10rem;
    left: 50%;
    transform: translateX(-50%);
    max-width: ${breakpoints.SMALL};
  }
`
