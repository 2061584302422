import { animated, config, useSpring } from 'react-spring'
import React, { FunctionComponent } from 'react'
import { useHeight } from './useHeight'

type Props = {
  animateOn: any
}

export const AnimateHeight: FunctionComponent<Props> = ({
  animateOn,
  children,
}) => {
  const [heightRef, height] = useHeight()
  const slideInStyles = useSpring({
    config: { ...config.stiff },
    from: { opacity: 0, height: 0 },
    to: {
      opacity: animateOn ? 1 : 0,
      height: animateOn ? height : 0,
    },
  })

  return (
    <animated.div style={{ ...slideInStyles, overflow: 'hidden' }}>
      <div ref={heightRef}>{children}</div>
    </animated.div>
  )
}
