import React, { FunctionComponent, useContext } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faVolumeUp } from '@fortawesome/free-solid-svg-icons'
import styled from 'styled-components'
import { borderRadius, colors, spacing } from '../../styles/styles'
import { AuthContext } from '../../contexts/AuthContext'

const SpeeechButtonStyled = styled.button`
  padding: ${spacing.sm} ${spacing.md};
  font-size: inherit;
  border-radius: ${borderRadius};
  border: 2px solid ${colors.black80};
  background: white;

  &:active {
    background: ${colors.black20};
  }
`

type Props = {
  text: string
}

export const SpeechButton: FunctionComponent<Props> = ({ text }) => {
  const { settings } = useContext(AuthContext)

  // @ts-ignore
  const isNative = window?.ReactNativeWebView
  const isTurnedOn = settings?.jodabook_text_to_speech

  const speech = (text: string) => {
    if (isNative) {
      // @ts-ignore
      window?.ReactNativeWebView.postMessage(
        JSON.stringify({
          type: 'SPEECH',
          payload: text,
        })
      )
    }
  }

  // Only show on mobile devices
  if (!isNative || !isTurnedOn) {
    return null
  }

  return (
    <SpeeechButtonStyled onClick={() => speech(text)}>
      <FontAwesomeIcon icon={faVolumeUp} />
    </SpeeechButtonStyled>
  )
}
