import { Card } from './PostCard'
import React, { FunctionComponent, useContext } from 'react'
import styled from 'styled-components'
import { borderRadius, colors, spacing } from '../../../styles/styles'
import { Link } from 'react-router-dom'
import { SpeechButton } from '../SpeechButton'
import { CardImage } from './CardImage'
import { CardVideo } from './CardVideo'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faImages } from '@fortawesome/free-solid-svg-icons'
import { AuthContext } from '../../../contexts/AuthContext'
import { environment } from '../../../environments'
import { List } from '../list/List'
import { CommentListItem } from '../../pages/book/CommentListItem'
import { reactionsConst } from '../../../constants'
import { CardHeader } from './CardHeader'
import { PostReactions } from './PostReactions'

export const PostCard = styled(Card)`
  margin-left: auto;
  margin-right: auto;
  max-width: 35rem;
`

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 ${spacing.md} ${spacing.md};
`

const Tags = styled.div`
  display: flex;
`

const StyledTag = styled(Link)`
  padding: ${spacing.xs} ${spacing.md};
  border-radius: ${borderRadius};
  background: ${colors.black5};

  &:link,
  &:visited {
    color: inherit;
    text-decoration: none;
  }

  :not(:last-child) {
    margin-right: ${spacing.sm};
  }
`

type Tag = {
  id: string
  name: string
  emotion: string
}

type Props = {
  post: any
  onDelete: () => void
  onVideoFetchingCompleted: (result: any) => void
}

export const Post: FunctionComponent<Props> = ({
  post,
  onDelete,
  onVideoFetchingCompleted,
}) => {
  const { authUser } = useContext(AuthContext)
  const isCreator = post.created_by.id === authUser?.id
  const postMedia =
    post && post.images.length > 0
      ? [...post.images]
      : post.videos.length > 0
      ? [...post.videos]
      : []

  return (
    <React.Fragment>
      <PostCard key={post.id}>
        <CardHeader isCreator={isCreator} post={post} onDelete={onDelete} />

        {postMedia.length > 0
          ? postMedia.map((media: any) => (
              <React.Fragment key={media.id}>
                {media.status ? (
                  <CardVideo
                    video={media}
                    onVideoFetchingCompleted={onVideoFetchingCompleted}
                  />
                ) : (
                  <CardImage minHeight={200} source={media} size="medium" />
                )}

                <Wrapper>
                  <Tags>
                    {media.tags &&
                      media.tags.map((tag: Tag) => (
                        <StyledTag key={tag.id} to={`/album/${tag.id}`}>
                          {tag.emotion ? (
                            <FontAwesomeIcon
                              icon={
                                reactionsConst.find(
                                  (x: any) => x.emotion === tag.emotion
                                )?.icon ?? faImages
                              }
                            />
                          ) : (
                            <>{tag.name}</>
                          )}
                        </StyledTag>
                      ))}
                  </Tags>
                </Wrapper>
              </React.Fragment>
            ))
          : null}

        {post.text && (
          <Wrapper>
            {post.text}
            <SpeechButton text={post.text} />
          </Wrapper>
        )}

        <PostReactions postId={post.id} reactions={post.cared} />

        {post.num_comments > 0 ? (
          <List
            emptyState="Ingen commentarer"
            url={`${environment.API_URL}/comments/parent/${post.id}`}
            ListItem={CommentListItem}
          />
        ) : null}
      </PostCard>
    </React.Fragment>
  )
}
