import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../../../contexts/AuthContext'
import styled from 'styled-components'
import { Card } from '../../shared/post/PostCard'
import { colors, spacing } from '../../../styles/styles'
import { ImageLoader } from '../../shared/ImageLoader'
import { PrimaryButton } from '../../shared/Buttons'
import { LargeAlbumView } from '../../shared/LargeAlbumView'
import { AddImagesToAlbum } from '../../shared/AddImagesToAlbum'
import { getAlbumImages, getProfileAlbumId } from '../../../lib/album.service'

const ImageList = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const SmallImageView = styled(Card)`
  display: flex;
  align-items: center;
  width: calc(100% / 4);
  background: ${colors.black5};
  justify-content: center;
  height: 120px;
  overflow: hidden;

  img {
    width: 100%;
    height: auto;
  }

  &:hover {
    cursor: pointer;
  }

  &.selected {
    background: ${colors.hopeDark};
  }
`

export const ProfileImages = () => {
  const { authUser } = useContext(AuthContext)
  const [status, setStatus] = useState<'idle' | 'pending' | 'failure'>('idle')
  const [images, setImages] = useState<any[]>([])
  const [profileTagId, setProfileTagId] = useState<string>('')
  const [imageIndex, setImageIndex] = useState<number | null>(null)

  // @ts-ignore
  const isNative = window?.ReactNativeWebView

  useEffect(() => {
    if (authUser?.id) {
      // Get images from tag with profile name - if it does not exist, create the tag
      setStatus('pending')

      getProfileAlbumId(authUser?.first_name)
        .then((id: string) => {
          setProfileTagId(id)
          return id
        })
        .then(getAlbumImages)
        .then(({ results }: any) => {
          setImages(results)
          setStatus('idle')
        })
        .catch(() => setStatus('failure'))
    }
  }, [authUser])

  const onImagesAdded = () =>
    getAlbumImages(profileTagId).then(({ results }: any) => setImages(results))

  const addNativeImages = () => {
    if (isNative) {
      // @ts-ignore
      window?.ReactNativeWebView.postMessage(
        JSON.stringify({
          type: 'ADD_IMAGES_TO_ALBUM',
          payload: { tagId: profileTagId },
        })
      )
    }
  }

  return status === 'pending' ? (
    <div style={{ textAlign: 'center' }}>
      <ImageLoader />
    </div>
  ) : (
    <React.Fragment>
      <h3>Meg i bilder</h3>

      <div style={{ marginBottom: spacing.md }}>
        {isNative ? (
          <PrimaryButton label="Legg til bilder" onClick={addNativeImages} />
        ) : (
          <AddImagesToAlbum
            albumId={profileTagId}
            onImagesAdded={onImagesAdded}
          />
        )}
      </div>

      <ImageList>
        {images.map((image, i) => (
          <SmallImageView key={`smimg-${i}`}>
            <img
              alt="Bilde fra galleri"
              src={image.small}
              onClick={() => setImageIndex(i)}
            />
          </SmallImageView>
        ))}
      </ImageList>

      {imageIndex !== null ? (
        <LargeAlbumView
          initialIndex={imageIndex}
          images={images}
          onClose={() => setImageIndex(null)}
        />
      ) : null}
    </React.Fragment>
  )
}
