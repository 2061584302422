import { spacing, colors } from '../../../styles/styles'
import React, { FunctionComponent, useState, useEffect } from 'react'
import ReactPlayer from 'react-player'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlayCircle } from '@fortawesome/free-solid-svg-icons'
import { Player } from '@lottiefiles/react-lottie-player'
import spinner from '../../../lottie/spinner.json'
import { ImageWrapper, Loading } from './CardImage'
import { environment } from '../../../environments'
import { authenticatedFetch } from '../../../lib/authenticatedFetch'
const VideoThumbnail = require('react-video-thumbnail')

type VideoProps = {
  video: any
  onVideoFetchingCompleted: (result: any) => void
}

export const CardVideo: FunctionComponent<VideoProps> = ({
  video,
  onVideoFetchingCompleted,
}) => {
  const [videoPlaying, setVideoPlaying] = useState<boolean>(false)
  const [thumbnail, setThumbnail] = useState(null)

  const handleCreateThumbnail = (thumbnail: any) => {
    setThumbnail(thumbnail)
  }
  useEffect(() => {
    if (video && video.status !== 'Ingest') {
      return
    }
    const id = setInterval(fetchPosts, 7000)
    return () => clearInterval(id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [video])

  const fetchPosts = async () => {
    authenticatedFetch(`${environment.API_URL}/posts`)
      .then(({ results }) => {
        if (
          results &&
          results.some((x: any) => x.videos && x.videos.length > 0) &&
          !results.some(
            (x: any) =>
              x.videos && x.videos.length > 0 && x.videos[0].status === 'Ingest'
          )
        ) {
          onVideoFetchingCompleted(results)
        }
      })
      .catch(() => {})
  }
  // @ts-ignore
  const isNative = window?.ReactNativeWebView
  return (
    <div style={{ position: 'relative', width: '100%' }}>
      {video.status && video.status === 'Complete' ? (
        <React.Fragment>
          <div style={{ display: 'none' }}>
            <VideoThumbnail
              videoUrl={video.mp4_urls[0]}
              thumbnailHandler={(thumbnail: any) =>
                handleCreateThumbnail(thumbnail)
              }
            />
          </div>
          <ReactPlayer
            light={thumbnail ?? false}
            url={video?.mp4_urls[0] ?? ''}
            playing={videoPlaying}
            onEnded={() => setVideoPlaying(false)}
            controls={true}
            playIcon={
              <FontAwesomeIcon
                icon={faPlayCircle}
                className="fa-5x"
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  backgroundColor: colors.white,
                  borderRadius: '50%',
                }}
                onClick={() => setVideoPlaying(!videoPlaying)}
              />
            }
            width="100%"
            height={isNative && '100%'}
            style={{
              paddingLeft: spacing.md,
              paddingRight: spacing.md,
            }}
          />
        </React.Fragment>
      ) : video.status === 'Error' ? (
        <ImageWrapper minHeight={200}>
          <Loading>
            <h6 style={{ color: colors.black, fontSize: '14px' }}>
              Kan ikke behandle videoen
            </h6>
          </Loading>
        </ImageWrapper>
      ) : (
        <ImageWrapper minHeight={200}>
          <Loading>
            <Player
              autoplay
              loop
              src={spinner}
              style={{ height: '100px', width: '100px' }}
            />

            {video.status === 'Ingest' && (
              <h6 style={{ color: colors.black, fontSize: '14px' }}>
                Videoen behandles
              </h6>
            )}

            {video.status === 'Unknown' && (
              <h6 style={{ color: colors.black, fontSize: '14px' }}>
                Venter på behandling
              </h6>
            )}
          </Loading>
        </ImageWrapper>
      )}
    </div>
  )
}
