import React, { useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { NavBar } from './navigation/NavBar'
import { useVideoCalls } from './contexts/VideoCallsProvider'
import { VideoCallPage } from './components/pages/video-call/VideoCallPage'
import { AuthContext } from './contexts/AuthContext'
import { breakpointsNumberMap, isSmallScreen } from './styles/styles'
import { SmallScreenView } from './SmallScreenView'
import { Routes } from './navigation/Routes'

const rootRoutes = ['/book', '/profile', '/album', '/contacts']

export const AuthenticatedApp = () => {
  const { settings } = useContext(AuthContext)
  const [displaySmallScreenView, setDisplaySmallScreenView] = useState(
    isSmallScreen()
  )
  const location = useLocation()
  const { videoCall } = useVideoCalls()

  useEffect(() => {
    const resizeHandler = () =>
      setDisplaySmallScreenView(
        window.innerWidth <= breakpointsNumberMap.MEDIUM
      )

    window.addEventListener('resize', resizeHandler)

    return () => window.removeEventListener('resize', resizeHandler)
  }, [])

  if (videoCall) {
    return <VideoCallPage />
  }

  const currentPathIsRoot = rootRoutes.indexOf(location.pathname) !== -1

  if (!settings) {
    return null
  }

  return displaySmallScreenView ? (
    <SmallScreenView currentPathIsRoot={currentPathIsRoot}>
      <Routes />
    </SmallScreenView>
  ) : (
    <React.Fragment>
      <NavBar />
      <Routes />
    </React.Fragment>
  )
}
