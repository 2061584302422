import React, { FormEvent, useContext, useState } from 'react'
import { Card } from '../../shared/post/PostCard'
import {
  borderRadius,
  breakpoints,
  colors,
  navBarHeightLarge,
  spacing,
} from '../../../styles/styles'
import styled from 'styled-components'
import { AuthContext } from '../../../contexts/AuthContext'
import { ErrorMessage } from '../../styled/Message'
import logo from '../../../images/jodacare-logo-liggende.png'

const LoginContainer = styled.div`
  padding: ${spacing.md};

  @media only screen and (min-width: ${breakpoints.MEDIUM}) {
    margin-left: auto;
    margin-right: auto;
    max-width: ${breakpoints.LARGE};
    padding: calc(${navBarHeightLarge} + ${spacing.md}) 0 ${spacing.lg};
  }
`

const LogoWrapper = styled.div`
  margin-bottom: ${spacing.lg};
  text-align: center;

  @media only screen and (min-width: ${breakpoints.MEDIUM}) {
    img {
      max-width: 20rem;
    }
  }
`

const JodaBookLogo = styled.img`
  width: 100%;
  height: auto;
`

const Form = styled.form`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media only screen and (min-width: ${breakpoints.MEDIUM}) {
    width: 50%;
  }
`

const FormField = styled.div`
  display: block;
  width: 100%;
  margin-bottom: ${spacing.lg};
`

const Label = styled.label`
  display: block;
  width: 100%;
  text-transform: uppercase;
`

const TextInput = styled.input`
  display: block;
  width: 100%;
  font-size: inherit;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid black;
  border-radius: 0;
`

const PrimaryButton = styled.button`
  min-width: 300px;
  color: white;
  height: 48px;
  font-size: inherit;
  border: none;
  border-radius: ${borderRadius};
  background: ${colors.safeDark};
  text-transform: uppercase;
  transition: all 0.25s ease-out;

  &:disabled {
    background: #e5e5e5;
  }

  &:hover {
    cursor: pointer;
  }
`

const LoginFooter = styled.div`
  margin-top: ${spacing.xl};
  text-align: center;

  p {
    margin: 0;
  }

  a {
    &:link,
    &:visited {
      color: inherit;
      text-decoration: none;
      border-bottom: 2px solid ${colors.warmth};
    }
  }
`

const LoginCard = styled(Card)`
  padding: ${spacing.xl};
`

export const LoginPage = () => {
  const { login, reqStatus } = useContext(AuthContext)
  const [error, setError] = useState<string>('')

  const [user, setUser] = useState<{ email: string; password: string }>({
    email: '',
    password: '',
  })

  const submit = (e: FormEvent) => {
    e.preventDefault()

    setError('')

    login(user).catch((error) => setError(error.message))
  }

  const loginButtonValid = Boolean(
    user.email !== '' && user.password !== '' && reqStatus !== 'pending'
  )
  // @ts-ignore
  const isNative = window?.ReactNativeWebView
  return (
    <LoginContainer>
      <LoginCard>
        <LogoWrapper>
          <JodaBookLogo src={logo} />
        </LogoWrapper>

        <Form onSubmit={submit}>
          <FormField>
            <Label htmlFor="email">E-postadresse</Label>
            <TextInput
              id="email"
              type="email"
              name="email"
              value={user.email}
              onChange={({ target }) =>
                setUser({ ...user, email: target.value.toLowerCase() })
              }
            />
          </FormField>

          <FormField>
            <Label htmlFor="password">Passord</Label>
            <TextInput
              id="password"
              type="password"
              name="password"
              value={user.password}
              onChange={({ target }) =>
                setUser({ ...user, password: target.value })
              }
            />
          </FormField>

          {error && <ErrorMessage>{error}</ErrorMessage>}

          <PrimaryButton type="submit" disabled={!loginButtonValid}>
            {reqStatus === 'pending' ? 'Logger inn...' : 'Logg inn'}
          </PrimaryButton>
          {!isNative && (
            <LoginFooter>
              <p>Har du ikke innlogging til Jodabook?</p>
              <a href="mailto:post@jodacare.com?subject=Jodabook">
                Ta kontakt med oss her
              </a>
            </LoginFooter>
          )}
          <p>
            <small>Versjon 3.0.0</small>
          </p>
        </Form>
      </LoginCard>
    </LoginContainer>
  )
}
