import React, {
  createContext,
  FunctionComponent,
  useContext,
  useState,
} from 'react'

type NavBarButton = { label: string; onClick: () => void }

type NavBarCtx = {
  title: string | null
  setTitle: (title: string) => void
  rightButtons?: NavBarButton[]
  setRightButtons: (btns: NavBarButton[]) => void
}

const NavBarContext = createContext<NavBarCtx>({
  title: null,
  setTitle: (title: string) => {},
  rightButtons: [],
  setRightButtons: (btns: NavBarButton[]) => {},
})

export const NavBarProvider: FunctionComponent = ({ children }) => {
  const [title, setTitle] = useState<string>('Jodabook')
  const [rightButtons, setRightButtons] = useState<NavBarButton[]>()

  return (
    <NavBarContext.Provider
      value={{
        title,
        setTitle,
        rightButtons,
        setRightButtons,
      }}
    >
      {children}
    </NavBarContext.Provider>
  )
}

export const useNavBar = () => {
  const { title, setTitle, rightButtons, setRightButtons } = useContext(
    NavBarContext
  )

  if (!setTitle) {
    throw new Error('useNavBar must be wrapped in a NavBarProvider')
  }

  return {
    title,
    setTitle,
    rightButtons,
    setRightButtons,
  }
}
